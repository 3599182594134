import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";
import { ToastrService } from "ngx-toastr";
import {
  PATIENT_ROLE,
  THERAPIST_AND_PATIENT_ROLE_LIST,
} from "../../../../sdk/src/lib/values/roles";
import { LOGIN_PATH } from "../../../../sdk/src/lib/values/shared-paths";

@Injectable({
  providedIn: "root",
})
export class HasPatientRoleAccessGuard implements CanActivate {
  constructor(
    private _realmClientService: RealmClientService,
    private _router: Router,
    private _toastrService: ToastrService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const USER_CUSTOM_DATA =
      this._realmClientService?.realmClient?.currentUser?.customData;
    if (
      THERAPIST_AND_PATIENT_ROLE_LIST.some((role) =>
        (USER_CUSTOM_DATA?.role_list as Array<string>)?.includes(role),
      )
    ) {
      return true;
    } else {
      return this._realmClientService.realmClient.currentUser?.logOut().then(
        () => {
          this._toastrService.error(
            "You do not have the correct permissions/privileges to access this application. Please contact your organisation's administrator for further information",
            "Incorrect Privileges/Permissions",
          );
          void this._router.navigate(["/", LOGIN_PATH]);
          return false;
        },
        () => {
          return false;
        },
      );
    }
  }
}
