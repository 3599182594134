import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { CheckBoxBlockComponent } from "../../../../../sdk/src/lib/check-box-block/check-box-block/check-box-block.component";
import { SessionStoreService } from "../../../../../sdk/src/lib/services/session-store.service";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-uplift-user-check-box-block",
  templateUrl: "./uplift-user-check-box-block.component.html",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgSelectModule],
})
export class UpliftUserCheckBoxBlockComponent
  extends CheckBoxBlockComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChildren("CheckBox") checkBoxList: QueryList<
    ElementRef<HTMLInputElement>
  >;
  @ViewChildren("CheckBoxImageMode") checkBoxImageModeList: QueryList<
    ElementRef<HTMLInputElement>
  >;

  multiSelectFormControl: FormControl = new FormControl<any>([]);
  private _unsubScribe: Subject<boolean> = new Subject<boolean>();

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
    if (!this.isInputDisabled) {
      this._multiSelectionSubscriber();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.data?.value) {
      this.multiSelectFormControl.setValue(this.data?.value);
    }
  }

  ngAfterViewInit() {
    this.checkBoxList.forEach((checkBox, index) => {
      checkBox.nativeElement.checked = false;
      if (this.data.value && this.data.value.includes(index)) {
        checkBox.nativeElement.checked = true;
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubScribe.next(false);
    this._unsubScribe.complete();
  }

  checkDisplayModeTextOrImageForCheckBox() {
    return (
      this.data.options?.length > 0 &&
      this.data.options.some(
        (checkOption) => checkOption.image_label_url?.length > 0,
      )
    );
  }

  private _multiSelectionSubscriber() {
    this.multiSelectFormControl.valueChanges
      .pipe(takeUntil(this._unsubScribe))
      .subscribe((selectedPositions) => {
        this.onMultiSelectionChanged(selectedPositions, this.data?.id);
      });
  }

  checkBoxDivSelection(index: number) {
    let checkBox = this.checkBoxList.get(index);
    checkBox.nativeElement.click();
  }

  checkBoxImageModeDivSelection(index: number) {
    let checkBox = this.checkBoxImageModeList.get(index);
    checkBox.nativeElement.click();
  }
}
