import * as React from "react";
import * as RadixSlider from "@radix-ui/react-slider";
import { motion, useMotionValue, useTransform } from "framer-motion";

const MAX_OVERFLOW = 50;

interface StepSliderProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  step?: number;
  min?: number;
  max?: number;
}

const StepSlider: React.FC<StepSliderProps> = ({
  value,
  setValue,
  step = 1,
  min = 0,
  max = 100,
}) => {
  const scale = useMotionValue(1);
  const ref = React.useRef<HTMLElement | null>(null);
  const overflow = useMotionValue(0);
  return (
    <motion.div
      style={{
        scale,
        opacity: useTransform(scale, [1, 1.2], [0.7, 1]),
      }}
      className="tw-flex tw-w-full tw-touch-none tw-select-none tw-items-center tw-justify-center tw-gap-3"
    >
      <RadixSlider.Root
        ref={ref}
        value={[value]}
        onValueChange={([v]) => setValue(Math.floor(v))}
        step={step}
        min={min}
        max={max}
        className="tw-relative tw-flex tw-w-full tw-grow tw-cursor-grab tw-touch-none tw-select-none tw-items-center tw-py-4 active:tw-cursor-grabbing"
      >
        <motion.div
          style={{
            scaleX: useTransform(() => {
              if (ref.current) {
                let { width } = ref.current.getBoundingClientRect();

                return 1 + overflow.get() / width;
              }
            }),
            scaleY: useTransform(overflow, [0, MAX_OVERFLOW], [1, 0.8]),
            height: useTransform(scale, [1, 1.2], [6, 12]),
          }}
          className="tw-flex tw-grow"
        >
          <RadixSlider.Track className="tw-relative tw-isolate tw-h-full tw-grow tw-overflow-hidden tw-rounded-full tw-bg-muted">
            <RadixSlider.Range className="tw-absolute tw-h-full tw-rounded-full tw-bg-gradient-to-r tw-from-red-500 tw-to-brand-main tw-transition-all" />
          </RadixSlider.Track>
          <RadixSlider.Thumb
            className="tw-block tw-size-5 -tw-translate-y-[7px] tw-rounded-full tw-bg-brand-main focus:tw-outline-none"
            aria-label="Volume"
          />
        </motion.div>
      </RadixSlider.Root>
    </motion.div>
  );
};

export default StepSlider;
