<section class="tw-block">
  <header *ngIf="data?.header?.length > 0">
    {{ data?.header }}
  </header>
  <audio
    (ended)="onAudioEnded($event)"
    (playing)="publishNewAudioPlay(data?.id)"
    controlsList="nodownload"
    #audioTemplateREF
    controls
    class="tw-w-full"
  >
    <source [src]="sanitizedAudioURL" [type]="audioFormatString" />
  </audio>
</section>
