import { Component } from "@angular/core";
import { TextBlockComponent } from "../../../../../sdk/src/lib/text-block/text-block/text-block.component";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-uplift-user-text-block",
  templateUrl: "./uplift-user-text-block.component.html",
  standalone: true,
  imports: [CommonModule],
})
export class UpliftUserTextBlockComponent extends TextBlockComponent {
  isContentVisible: boolean = false;

  constructor() {
    super();
  }
}
