import { Component, Input, OnInit } from "@angular/core";
import { InputBlockComponent } from "../../input-block/input-block/input-block.component";
import { SelectBlock } from "../../types/block";
import { SessionStoreService } from "../../services/session-store.service";

@Component({
  selector: "ail-select-block",
  template: "",
  standalone: true
})
export class SelectBlockComponent extends InputBlockComponent
  implements OnInit {
  @Input() data: SelectBlock;

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }
}
