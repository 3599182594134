import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { FooterComponent } from "../../../shared/footer/footer.component";

@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  standalone: true,
  imports: [RouterLink, FooterComponent],
})
export class ErrorPageComponent {}
