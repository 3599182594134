import { Routes } from "@angular/router";
import {
  COMPLETED_QUEST_ENTRY_PATH,
  COMPLETED_QUEST_ROUTE,
  COURSE_ROUTE,
  COURSE_ROUTE_PARAM,
  GROUP_PATH,
  GROUP_ROUTE_PARAM,
  LIFE_PRACTICE_LIST_LP_ROUTE,
  LIFE_PRACTICE_MODULE_ITEM_ID_ROUTE_PARAM,
  LIFE_PRACTICE_ROUTE,
  LIFE_PRACTICE_TABLE_LP_ROUTE,
  MODULE_SHELL_ID_PARAM,
  PROTOCOL_DETAIL_ROUTE_PARAM,
  PROTOCOL_ROUTE,
  QUEST_PATH,
  SESSION_ROUTE,
  SESSION_ROUTE_PARAM,
  SHELL_QUEST_PAGE,
  UPDATE_PROFILE,
  WEBINAR_PATH,
  WEBINAR_ROUTE_PARAM,
} from "../../../../../sdk/src/lib/values/shared-paths";

export const CONTENT_ROUTES: Routes = [
  {
    path: QUEST_PATH,
    loadChildren: () =>
      import("../../quest/quest.module").then((m) => m.QuestModule),
  },
  {
    path: `${WEBINAR_PATH}/:${WEBINAR_ROUTE_PARAM}`,
    loadComponent: () =>
      import("../../webinar/webinar.component").then(
        (module) => module.WebinarComponent,
      ),
  },
  {
    path: UPDATE_PROFILE,
    loadChildren: () =>
      import("../../profile-onboarding/profile-onboarding.module").then(
        (m) => m.ProfileOnboardingModule,
      ),
  },
  {
    path: `${COMPLETED_QUEST_ROUTE}/${COMPLETED_QUEST_ENTRY_PATH}`,
    loadChildren: () =>
      import(
        "../../uplift-user-quest-result/uplift-user-quest-result.module"
      ).then((m) => m.UpliftUserQuestResultModule),
  },
  {
    path: `${SHELL_QUEST_PAGE}`,
    loadComponent: () =>
      import("../../shell-quest/shell-quest.component").then(
        (module) => module.ShellQuestComponent,
      ),
  },
  {
    path: `${SHELL_QUEST_PAGE}/:${MODULE_SHELL_ID_PARAM}/${PROTOCOL_ROUTE}/:${PROTOCOL_DETAIL_ROUTE_PARAM}/${GROUP_PATH}/:${GROUP_ROUTE_PARAM}/${SESSION_ROUTE}/:${SESSION_ROUTE_PARAM}`,
    loadComponent: () =>
      import("../../shell-quest/shell-quest.component").then(
        (module) => module.ShellQuestComponent,
      ),
  },
  {
    path: LIFE_PRACTICE_ROUTE,
    loadChildren: () =>
      import("../../life-practice/life-practice.module").then(
        (module) => module.LifePracticeModule,
      ),
  },
  {
    path: `${LIFE_PRACTICE_ROUTE}/${GROUP_PATH}/:${GROUP_ROUTE_PARAM}/${COURSE_ROUTE}/:${COURSE_ROUTE_PARAM}/${SESSION_ROUTE}/:${SESSION_ROUTE_PARAM}/:${LIFE_PRACTICE_MODULE_ITEM_ID_ROUTE_PARAM}/${LIFE_PRACTICE_LIST_LP_ROUTE}`,
    loadComponent: () =>
      import("../../life-practice-list/life-practice-list.component").then(
        (module) => module.LifePracticeListComponent,
      ),
  },
  {
    path: `${LIFE_PRACTICE_ROUTE}/${GROUP_PATH}/:${GROUP_ROUTE_PARAM}/${COURSE_ROUTE}/:${COURSE_ROUTE_PARAM}/${SESSION_ROUTE}/:${SESSION_ROUTE_PARAM}/:${LIFE_PRACTICE_MODULE_ITEM_ID_ROUTE_PARAM}/${LIFE_PRACTICE_TABLE_LP_ROUTE}`,
    loadComponent: () =>
      import("../../life-practice-table/life-practice-table.component").then(
        (module) => module.LifePracticeTableComponent,
      ),
  },
];
