<div class="block full-width rounded">
  <div class="block-header full-width p-2" *ngIf="data?.header?.length > 0">
    {{ data?.header }}
  </div>
  <div class="block-body full-width p-2">
    <div class="smart-checkbox-block full-width form-group">
      <form [formGroup]="inputFormControl" *ngIf="inputFormControl">
        <ng-container *ngFor="let smartCheckbox of data.options; index as i">
          <div class="form-check my-2">
            <input
              *ngIf="!isInputDisabled"
              (change)="onSelectionChanged($event, data.id, smartCheckbox.position)"
              [id]="data.id + smartCheckbox.position.toString() + i"
              class="form-check-input"
              [formControlName]="data.position.toString()"
              type="checkbox"
              [value]="smartCheckbox.value"
              [attr.name]="data.id + smartCheckbox.position.toString()"
            />

            <input
              [checked]="isSelectionChecked(smartCheckbox.position, data.value)"
              *ngIf="isInputDisabled"
              [id]="data.id + smartCheckbox.position.toString() + i"
              class="form-check-input"
              type="checkbox"
              [disabled]="isInputDisabled"
              [value]="smartCheckbox.value"
              [attr.name]="data.id + smartCheckbox.position.toString()"
            />
            <label
              class="form-check-label smart-checkbox-block-label ml-2"
              [attr.for]="data.id + smartCheckbox.position.toString()"
            >
              {{ smartCheckbox.label }}
            </label>
          </div>
          <ng-container *ngIf="showCheckboxAnswers || isInputDisabled">
            <p
              class="text-success"
              *ngIf="smartCheckbox?.correct_selection === true"
            >
              <b>This is a correct answer</b>
            </p>
            <p
              class="text-danger"
              *ngIf="smartCheckbox?.correct_selection === false"
            >
              This answer is incorrect
            </p>
          </ng-container>
        </ng-container>

        <button
          [disabled]="showCheckboxAnswers === true"
          (click)="viewCheckBoxAnswers()"
          class="btn btn-raised btn-primary"
        >
          View Answers
        </button>
      </form>
    </div>
  </div>
</div>
