export const environment = {
  appID: "ai-leap-ilgjp",
  production: false,
  realm: {
    allContentCollection: "interventions",
    allContentDB: "orbit_project",
    appID: "ai-leap-ilgjp",
    assessmentCollection: "all_content_test",
    assessmentDB: "content",
    createWebinarFunction: "CREATE_WEBINAR_MEETING_DEV",
    currentProgressCollection: "interventions_being_executed_test",
    databaseName: "orbit_project",
    groupListCollection: "groups_test",
    groupProtocolLisCollection: "set_protocols",
    groupProtocolListDB: "orbit_project",
    groupsListDB: "orbit_project",
    journalEntryCollection: "journal_item_entries_test",
    lifePracticeCollection: "life_practice_test",
    lifePracticeCustomCollection: "custom_life_practices",
    lifePracticeCustomEntriesCollection: "custom_life_practice_entries_test",
    lifePracticeResultCollection: "session_results",
    lifePracticeResultDB: "orbit_project",
    liveQuestCollection: "orbit_live_quest_test",
    liveQuestDB: "live_quest",
    organisationCollection: "organisations_dev",
    patientProfileCollection: "patient_profile_test",
    profileDB: "profile",
    protocolCollection: "protocols",
    protocolDB: "content",
    questResultCollection: "session_results",
    questResultDB: "orbit_project",
    serviceName: "mongodb-atlas",
    sessionCollection: "all_content_test",
    sessionDB: "content",
    sessionResultDB: "result_test",
    sessionResultsCollection: "session_results",
    updateWebinarFunction: "CREATE_WEBINAR_MEETING_DEV",
    userProfileCollection: "profile",
    userProfileDB: "uplift_user",
    webinarDB: "orbit_project",
    webinarListCollection: "webinars_test",
    getSmsNumberVerificationCodeFunction:
      "GET_SMS_NUMBER_VERIFICATION_CODE_TEST",
    confirmSmsNumberVerificationFunction:
      "CONFIRM_SMS_NUMBER_VERIFICATION_CODE_TEST",
    skillEntryCollection: "skill_check_entries_test",
  },
};
