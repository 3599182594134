import { Component, Input, OnInit } from "@angular/core";
import { InputBlockComponent } from "../../input-block/input-block/input-block.component";
import { RadioBlock } from "../../types/block";
import { SessionStoreService } from "../../services/session-store.service";

@Component({
  selector: "ail-radio-block",
  template: "",
  standalone: true,
})
export class RadioBlockComponent extends InputBlockComponent implements OnInit {
  @Input() data: RadioBlock;

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }

  onSelectionChange(position: number) {
    this.data.value = position;
    super.onBlockDataChange(this.data);
  }
}
