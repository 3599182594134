import { Routes } from "@angular/router";
import {
  COMPLETED_QUEST_ROUTE,
  COURSE_MENU_ITEM_ROUTE,
  COURSE_ROUTE_PARAM,
  EMERGENCY_HELP_PATH,
  HOME_PATH,
  MY_JOURNALS_PATH,
  THERAPIST_SESSION_PATH,
  UPDATE_PROFILE,
} from "../../../../../sdk/src/lib/values/shared-paths";
import { EmergencyHelpComponent } from "../../emergency-help/emergency-help.component";

export const Full_ROUTES: Routes = [
  {
    path: HOME_PATH,
    loadComponent: () =>
      import("../../home/home-page.component").then((m) => m.HomePageComponent),
  },
  {
    path: COMPLETED_QUEST_ROUTE,
    loadChildren: () =>
      import("../../completed-quests/completed-quests.module").then(
        (m) => m.CompletedQuestsModule,
      ),
  },
  {
    path: MY_JOURNALS_PATH,
    loadComponent: () =>
      import("../../journal/journal.component").then(
        (journalComponent) => journalComponent.JournalComponent,
      ),
  },
  {
    path: THERAPIST_SESSION_PATH,
    loadComponent: () =>
      import("../../therapist-session/therapist-session.component").then(
        (therapistSessionComponent) =>
          therapistSessionComponent.TherapistSessionComponent,
      ),
  },
  {
    path: `${COURSE_MENU_ITEM_ROUTE}/:${COURSE_ROUTE_PARAM}`,
    loadComponent: () =>
      import(
        "../../course-menu-item-view/course-menu-item-view.component"
      ).then(
        (courseMenuItemComponent) =>
          courseMenuItemComponent.CourseMenuItemViewComponent,
      ),
  },
  {
    path: `${UPDATE_PROFILE}`,
    loadComponent: () =>
      import("../../edit-patient-profile/edit-patient-profile.component").then(
        (editPatientProfileComponent) =>
          editPatientProfileComponent.EditPatientProfileComponent,
      ),
  },
  {
    path: EMERGENCY_HELP_PATH,
    component: EmergencyHelpComponent,
  },
];
