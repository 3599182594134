import { Component, OnDestroy } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { AudioBlockComponent } from "../../../../../sdk/src/lib/audio-block/audio-block/audio-block.component";
import { AudioBlockService } from "../../../../../sdk/src/lib/services/audio-block.service";

@Component({
  selector: "app-uplift-user-audio-block",
  templateUrl: "./uplift-user-audio-block.component.html",
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class UpliftUserAudioBlockComponent
  extends AudioBlockComponent
  implements OnDestroy
{
  private _audioPauseSubscription: Subscription;
  private _audioPlaySubscription: Subscription;

  constructor(
    protected _domSanitizer: DomSanitizer,
    protected _audioBlockService: AudioBlockService,
  ) {
    super(_domSanitizer);

    this._audioPauseSubscription = _audioBlockService.onPageChange$.subscribe(
      (hasPageChanged) => {
        if (hasPageChanged) {
          this._pauseAudio();
        }
      },
    );
    this._audioPlaySubscription = _audioBlockService.onAudioPlaying$.subscribe(
      (audioBlockID) => {
        if (audioBlockID !== this.data?.id) {
          this._pauseAudio();
        }
      },
    );
  }

  private _pauseAudio() {
    if (
      this._audioTemplateRef !== null &&
      this._audioTemplateRef !== undefined
    ) {
      this._audioTemplateRef.element.nativeElement.pause();
    }
  }

  ngOnDestroy(): void {
    this._audioPauseSubscription.unsubscribe();
    this._audioPlaySubscription.unsubscribe();
  }

  publishNewAudioPlay(blockID: string) {
    this._audioBlockService.onAudioPlaying$.next(blockID);
    const audioElement = document.querySelector("audio");

    audioElement.addEventListener("play", function () {
      this.classList.add("playing");
    });

    audioElement.addEventListener("pause", function () {
      this.classList.remove("playing");
    });
  }

  onAudioEnded(event: any) {
    const CURRENT_TARGET = event.currentTarget;
    CURRENT_TARGET.currentTime = 0;
    CURRENT_TARGET.load();
  }
}
