import * as React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { AnimatePresence } from "framer-motion";
import Confetti from "react-confetti-boom";
import FinalScreen from "./FinalScreen";
import SkillForm from "./SkillForm";
import { ICourseTheme, SkillFormData } from "./wrapper";
import ModulesList from "./ModulesList";
import ThemesList from "./ThemesList";
import { useSkillCheckin } from "./SkillCheckinProvider";
import { SkillEntry } from "projects/sdk/src/lib/types/module-shell";

export const SkillsCheckInIntroText: React.FC<React.PropsWithChildren> = ({
  children,
}) => <p className="tw-font-semibold tw-leading-tight">{children}</p>;

export const variants = {
  hide: { x: "100%", opacity: 0 },
  show: { x: 0, opacity: 100 },
};

interface SkillsCheckInProps {
  formSubmitWrapper: (data: SkillFormData, cb: () => void) => void;
  completedSkillEntries: SkillEntry[] | null;
  themes: ICourseTheme[];
}

const SkillsCheckIn: React.FC<SkillsCheckInProps> = ({
  formSubmitWrapper,
  themes,
  completedSkillEntries,
}) => {
  const { currentStep, setCurrentStep, selectedSkill, selectedModule } =
    useSkillCheckin();
  const dialogContent = React.useRef<HTMLDivElement>(null);
  const count: number = React.useMemo(() => {
    if (
      completedSkillEntries?.length === 0 ||
      !selectedModule ||
      !selectedSkill
    ) {
      return 0;
    }
    return completedSkillEntries.filter(({ theme_id, module_shell_id }) => {
      return (
        selectedSkill.theme._id === theme_id &&
        module_shell_id === selectedModule.module_shell._id.toString()
      );
    }).length;
  }, [completedSkillEntries, selectedSkill, selectedModule]);

  React.useEffect(() => {
    dialogContent?.current?.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <>
      <Dialog.Root
        onOpenChange={(open) => {
          if (!open) setCurrentStep(0);
        }}
      >
        <Dialog.Trigger asChild>
          <button type="button" className="tw-button tw-font-text">
            <span>
              <i className="ft-user-check tw-mr-2 tw-font-bold" />
              Skills Check-in
            </span>
          </button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="tw-fixed tw-inset-0 tw-z-[9999] tw-bg-black/60 tw-backdrop-blur data-[state=open]:tw-animate-overlayShow" />
          <Dialog.Content
            className="tw-fixed tw-left-1/2 tw-top-1/2 tw-z-[99998] tw-max-h-[100dvh] tw-origin-center -tw-translate-x-1/2 -tw-translate-y-1/2 tw-overflow-y-auto data-[state=open]:tw-animate-contentShow"
            ref={dialogContent}
          >
            <Dialog.Title className="tw-sr-only">Skills Check-in</Dialog.Title>
            <Dialog.Description className="tw-sr-only"></Dialog.Description>
            <section className="tw-card tw-flex tw-min-h-[640px] tw-w-svw tw-flex-col tw-overflow-clip sm:tw-max-w-[375px]">
              <header className="-tw-mx-4 tw-mb-6 tw-flex tw-items-center tw-justify-between tw-gap-2 tw-border-b tw-border-border tw-px-4 tw-pb-4 md:-tw-mx-6 md:tw-px-6">
                <button
                  className="tw-link tw-text-sm tw-font-semibold disabled:tw-pointer-events-none disabled:tw-invisible"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  disabled={currentStep === 3 || currentStep === 0}
                >
                  <i className="ft-arrow-left tw-font-bold" />
                  <span>Back</span>
                </button>
                <div className="tw-line-clamp-2 tw-grow tw-text-center tw-text-lg tw-font-bold tw-leading-tight tw-text-brand-main">
                  {currentStep === 0 && "Skills Check-in"}
                  {currentStep === 1 &&
                    selectedSkill &&
                    selectedSkill.theme.title}
                  {currentStep === 2 &&
                    selectedModule &&
                    selectedModule.module_item.title}
                  {currentStep === 3 && "Well done!"}
                </div>
                <Dialog.Close asChild>
                  <button
                    type="button"
                    className="tw-link tw-shrink-0 tw-text-sm"
                  >
                    <span className="tw-sr-only">Exit</span>
                    <i className="ft-log-out tw-ml-1 tw-font-bold" />
                  </button>
                </Dialog.Close>
              </header>
              <div className="tw-flex tw-flex-1 tw-flex-col">
                <AnimatePresence initial={false}>
                  {currentStep === 0 && <ThemesList themes={themes} />}
                  {currentStep === 1 && selectedSkill && <ModulesList />}
                  {currentStep === 2 && selectedModule && (
                    <SkillForm formSubmitWrapper={formSubmitWrapper} />
                  )}
                  {currentStep === 3 && <FinalScreen count={count} />}
                </AnimatePresence>
              </div>
            </section>
          </Dialog.Content>
          {currentStep === 3 && (
            <div className="tw-pointer-events-none tw-fixed tw-inset-0 tw-z-[99999] tw-scale-150">
              <Confetti mode="boom" particleCount={100} />
            </div>
          )}
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default SkillsCheckIn;
