import { Inject, Injectable } from "@angular/core";
import { Environment, ENVIRONMENT_TOKEN } from "../types/environment.token";
import { SkillEntry } from "../types/module-shell";
import { from, Observable } from "rxjs";
import { RealmClientService } from "./realm-client.service";

@Injectable({
  providedIn: "root",
})
export class SkillEntryServiceService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment,
  ) {}

  createEntry(skillEntry: SkillEntry): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.skillEntryCollection)
        .insertOne(skillEntry),
    );
  }

  getSkillEntryByPatientIdList(userID: string): Observable<Array<SkillEntry>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection<SkillEntry>(this._environmentToken.skillEntryCollection)
        .find({
          owner_user_id: userID,
        }),
    );
  }
}
