<section class="tw-block">
  <div
    *ngIf="
      data?.collapse_label &&
      data?.collapse_label !== '' &&
      isContentVisible === false
    "
    class="tw-mb-4"
  >
    <button
      type="button"
      class=""
      (click)="isContentVisible = !isContentVisible"
    >
      {{ data?.collapse_label }}
    </button>
  </div>

  <ng-container *ngIf="isContentVisible === true || !data?.collapse_label">
    <header
      [class.bg-info]="data?.type != 'html'"
      *ngIf="data?.header?.length > 0"
    >
      {{ data?.header }}
    </header>
    <div
      class="tw-font-text tw-prose tw-prose-stone tw-prose-brand md:tw-prose-lg tw-max-w-full"
      [innerHTML]="safeHTML"
    ></div>
  </ng-container>
</section>
