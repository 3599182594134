import * as React from "react";
import { motion } from "framer-motion";
import { variants } from ".";
import * as Dialog from "@radix-ui/react-dialog";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import awardAnimation from "../../animations/award-animation.mjs";
import { QUICK_SKILLS_TRANSLATION, REWARDS } from "./config";
import { useSkillCheckin } from "./SkillCheckinProvider";

interface FinalScreenProps {
  count: number;
}

const FinalScreen: React.FC<FinalScreenProps> = ({ count }) => {
  const { setCurrentStep } = useSkillCheckin();
  const message = React.useMemo(
    () =>
      REWARDS.find(({ level }) => level === count + 1)?.label ||
      REWARDS[REWARDS.length - 1].label,
    [count],
  );

  return (
    <motion.div
      className="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-text-center [&>canvas]:!tw-fixed [&>canvas]:!tw-inset-0 [&>canvas]:!tw-h-screen [&>canvas]:!tw-w-screen"
      initial={variants.hide}
      animate={variants.show}
    >
      <div className="tw-text-3xl tw-font-black tw-text-brand-main">
        {message}
      </div>
      <p className="tw-py-4">
        {QUICK_SKILLS_TRANSLATION.FINAL_SCREEN.COMPLETED_TITLE}{" "}
        <strong>{count + 1}</strong>{" "}
        {QUICK_SKILLS_TRANSLATION.FINAL_SCREEN.COMPLETED_TIMES}
      </p>
      <div className="tw-card !tw-pt-0 tw-leading-tight">
        <Lottie
          animationData={awardAnimation}
          loop={false}
          play
          className="tw-square tw-mx-auto tw-w-[250px]"
        />
        <p className="tw-mb-4 tw-text-lg tw-font-bold tw-leading-tight tw-text-brand-main">
          {QUICK_SKILLS_TRANSLATION.FINAL_SCREEN.AWARD_TITLE}
        </p>
        <p className="tw-px-4">
          {QUICK_SKILLS_TRANSLATION.FINAL_SCREEN.AWARD_DESC}
        </p>
      </div>
      <div className="tw-mt-4 tw-space-y-4">
        <button
          type="button"
          className="tw-button tw-w-full"
          onClick={() => setCurrentStep(0)}
        >
          <span>
            {QUICK_SKILLS_TRANSLATION.FINAL_SCREEN.RECORD_ANOTHER_ACTION}
          </span>
        </button>
        <Dialog.Close asChild>
          <button type="button" className="tw-button tw-w-full">
            <span>{QUICK_SKILLS_TRANSLATION.FINAL_SCREEN.CLOSE_ACTION}</span>
          </button>
        </Dialog.Close>
      </div>
    </motion.div>
  );
};

export default FinalScreen;
