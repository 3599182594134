<main role="main" class="tw-font-text">
  <header>
    <h1 class="tw-page-title">Emergency Help Kit</h1>
  </header>
  <ng-container *ngIf="organisationProfile$ | async; else loading">
    <h2 class="tw-font-text tw-mb-6 tw-text-xl tw-font-bold tw-text-brand-main">
      {{ (organisationProfile$ | async)?.name }}
    </h2>
    <section
      *ngIf="
        !!(organisationProfile$ | async)?.emergency_information;
        else noResults
      "
      class="tw-card w-w-full tw-mx-auto tw-max-w-screen-lg tw-space-y-6 !tw-pb-0"
    >
      <div [innerHTML]="
        (organisationProfile$ | async)?.emergency_information.patient_emergency_kit
      "
        class="tw-font-text tw-prose tw-prose-stone tw-prose-brand md:tw-prose-lg tw-max-w-full"
      ></div>
      <p>
        <a
          *ngIf="
            (organisationProfile$ | async)?.emergency_information
              ?.information_leaflet_url
          "
          [href]="
            (organisationProfile$ | async)?.emergency_information
              ?.information_leaflet_url
          "
          target="_blank"
          class="tw-button"
        >
          <span>
            <i class="ft-file-text tw-mr-2"></i>
            Open the PDF Leaflet
          </span>
        </a>
      </p>
    </section>
    <ng-template #noResults>
      <p class="tw-text-center tw-font-semibold tw-text-muted-foreground">
        No content found, please come back later.
      </p>
    </ng-template>
  </ng-container>
  <ng-template #loading><app-loader /></ng-template>
</main>
