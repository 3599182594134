import { Component, Input, OnInit } from "@angular/core";
import { AbstractBlockComponent } from "../abstract-block/abstract-block/abstract-block.component";
import { PDF_Block } from "../types/block";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "ail-pdf-block",
  standalone: true,
  imports: [AbstractBlockComponent],
  template: "",
})
export class PdfBlockComponent
  extends AbstractBlockComponent
  implements OnInit
{
  @Input() data: PDF_Block;
  @Input() mode: string;
  sanitizedPdfURL: SafeUrl;

  constructor(protected _domSanitizer: DomSanitizer) {
    super();
  }
  ngOnInit() {
    this.sanitizedPdfURL = this._domSanitizer.bypassSecurityTrustResourceUrl(
      this.data.source_url
    );
  }
}
