<div class="block full-width rounded">
  <div
    class="block-header full-width p-2"
    *ngIf="data?.header?.length > 0"
  >
    {{ data?.header }}
  </div>
  <div class="block-body full-width">
    <div class="smart-radio-block full-width form-group">
      <form [formGroup]="inputFormControl" *ngIf="inputFormControl">
        <ng-container *ngFor="let radioButton of data.options; index as i">
          <div class="form-check">
            <input
              *ngIf="!isInputDisabled"
              [id]="data.id + data.position.toString() + i"
              class="form-check-input"
              [formControlName]="data.id"
              type="radio"
              [value]="radioButton.position"
              [attr.name]="data.id"
            />

            <input
              *ngIf="isInputDisabled"
              [id]="data.id + data.position.toString() + i"
              class="form-check-input"
              [checked]="data.value === radioButton.position"
              type="radio"
              disabled
              [value]="radioButton.position"
              [attr.name]="data.id"
            />
            <label
              class="form-check-label radio-block-label ml-2"
              [attr.for]="data.id + data.position.toString()"
            >
              {{ radioButton.label }}
            </label>
          </div>
          <ng-container
            *ngIf="
              isInputDisabled ||
              inputFormControl.get(data.id).dirty &&
              data.options[inputFormControl.get(data.id).value]
                ?.correct_selection !== true
            "
          >
            <p
              class="text-success"
              *ngIf="radioButton?.correct_selection === true"
            >
              <b>This is the correct answer</b>
            </p>
            <p
              class="text-danger"
              *ngIf="radioButton?.correct_selection === false"
            >
              This answer is incorrect
            </p>
          </ng-container>
        </ng-container>

        <p
          class="text-success mt-1"
          *ngIf="
            data.options[inputFormControl.get(data.id).value]?.correct_selection
          "
          >{{
            data.options[inputFormControl.get(data.id).value]?.feedback_text
          }}</p
        >
      </form>
    </div>
  </div>
</div>
