import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PdfBlockComponent } from "../../../../sdk/src/lib/pdf-block/pdf-block.component";

@Component({
  selector: "app-uplift-user-pdf-block",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./uplift-user-pdf-block.component.html",
})
export class UpliftUserPdfBlockComponent extends PdfBlockComponent {}
