export default {
  v: "5.9.4",
  fr: 30,
  ip: 0,
  op: 61,
  w: 500,
  h: 500,
  nm: "Badge",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Star 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 20,
              s: [0],
            },
            { t: 50, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [155.519, 164.081, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 35,
              s: [70, 70, 100],
            },
            { t: 50, s: [0, 0, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.632, -4.391],
                    [17.75, -0.86],
                    [18.628, 0.623],
                    [4.938, 4.309],
                    [4.391, 4.632],
                    [0.86, 17.75],
                    [-0.623, 18.628],
                    [-4.309, 4.938],
                    [-4.632, 4.391],
                    [-17.75, 0.86],
                    [-18.628, -0.623],
                    [-4.938, -4.309],
                    [-4.391, -4.632],
                    [-0.86, -17.75],
                    [0.623, -18.628],
                    [4.309, -4.938],
                  ],
                  o: [
                    [4.938, -4.309],
                    [18.628, -0.623],
                    [17.75, 0.86],
                    [4.632, 4.391],
                    [4.309, 4.938],
                    [0.623, 18.628],
                    [-0.86, 17.75],
                    [-4.391, 4.632],
                    [-4.938, 4.309],
                    [-18.628, 0.623],
                    [-17.75, -0.86],
                    [-4.632, -4.391],
                    [-4.309, -4.938],
                    [-0.623, -18.628],
                    [0.86, -17.75],
                    [4.391, -4.632],
                  ],
                  v: [
                    [4.938, -4.309],
                    [17.75, -0.86],
                    [17.75, 0.86],
                    [4.938, 4.309],
                    [4.309, 4.938],
                    [0.86, 17.75],
                    [-0.86, 17.75],
                    [-4.309, 4.938],
                    [-4.938, 4.309],
                    [-17.75, 0.86],
                    [-17.75, -0.86],
                    [-4.938, -4.309],
                    [-4.309, -4.938],
                    [-0.86, -17.75],
                    [0.86, -17.75],
                    [4.309, -4.938],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.6314, 0.6784, 0.7176, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Star",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 20,
      op: 320,
      st: 20,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Star 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 13,
              s: [0],
            },
            { t: 43, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [127.953, 244.276, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 28,
              s: [100, 100, 100],
            },
            { t: 43, s: [0, 0, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.9699999999999998, -2.816],
                    [11.382, -0.551],
                    [11.945, 0.399],
                    [3.166, 2.763],
                    [2.816, 2.9699999999999998],
                    [0.551, 11.382],
                    [-0.399, 11.945],
                    [-2.763, 3.166],
                    [-2.9699999999999998, 2.816],
                    [-11.382, 0.551],
                    [-11.945, -0.399],
                    [-3.166, -2.763],
                    [-2.816, -2.9699999999999998],
                    [-0.551, -11.382],
                    [0.399, -11.945],
                    [2.763, -3.166],
                  ],
                  o: [
                    [3.166, -2.763],
                    [11.945, -0.399],
                    [11.382, 0.551],
                    [2.9699999999999998, 2.816],
                    [2.763, 3.166],
                    [0.399, 11.945],
                    [-0.551, 11.382],
                    [-2.816, 2.9699999999999998],
                    [-3.166, 2.763],
                    [-11.945, 0.399],
                    [-11.382, -0.551],
                    [-2.9699999999999998, -2.816],
                    [-2.763, -3.166],
                    [-0.399, -11.945],
                    [0.551, -11.382],
                    [2.816, -2.9699999999999998],
                  ],
                  v: [
                    [3.166, -2.763],
                    [11.382, -0.551],
                    [11.382, 0.551],
                    [3.166, 2.763],
                    [2.763, 3.166],
                    [0.551, 11.382],
                    [-0.551, 11.382],
                    [-2.763, 3.166],
                    [-3.166, 2.763],
                    [-11.382, 0.551],
                    [-11.382, -0.551],
                    [-3.166, -2.763],
                    [-2.763, -3.166],
                    [-0.551, -11.382],
                    [0.551, -11.382],
                    [2.763, -3.166],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.6314, 0.6784, 0.7176, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Star",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 13,
      op: 313,
      st: 13,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Star",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 8,
              s: [0],
            },
            { t: 38, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [366.519, 140.081, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 8,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 23,
              s: [100, 100, 100],
            },
            { t: 38, s: [0, 0, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.632, -4.391],
                    [17.75, -0.86],
                    [18.628, 0.623],
                    [4.938, 4.309],
                    [4.391, 4.632],
                    [0.86, 17.75],
                    [-0.623, 18.628],
                    [-4.309, 4.938],
                    [-4.632, 4.391],
                    [-17.75, 0.86],
                    [-18.628, -0.623],
                    [-4.938, -4.309],
                    [-4.391, -4.632],
                    [-0.86, -17.75],
                    [0.623, -18.628],
                    [4.309, -4.938],
                  ],
                  o: [
                    [4.938, -4.309],
                    [18.628, -0.623],
                    [17.75, 0.86],
                    [4.632, 4.391],
                    [4.309, 4.938],
                    [0.623, 18.628],
                    [-0.86, 17.75],
                    [-4.391, 4.632],
                    [-4.938, 4.309],
                    [-18.628, 0.623],
                    [-17.75, -0.86],
                    [-4.632, -4.391],
                    [-4.309, -4.938],
                    [-0.623, -18.628],
                    [0.86, -17.75],
                    [4.391, -4.632],
                  ],
                  v: [
                    [4.938, -4.309],
                    [17.75, -0.86],
                    [17.75, 0.86],
                    [4.938, 4.309],
                    [4.309, 4.938],
                    [0.86, 17.75],
                    [-0.86, 17.75],
                    [-4.309, 4.938],
                    [-4.938, 4.309],
                    [-17.75, 0.86],
                    [-17.75, -0.86],
                    [-4.938, -4.309],
                    [-4.309, -4.938],
                    [-0.86, -17.75],
                    [0.86, -17.75],
                    [4.309, -4.938],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.6314, 0.6784, 0.7176, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Star",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 8,
      op: 308,
      st: 8,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Badge Star",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [251.181, 242.144, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          k: [
            {
              s: [0, 0, 100],
              t: 8,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [7.678, 7.678, 100],
              t: 9,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [25.248, 25.248, 100],
              t: 10,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [48.393, 48.393, 100],
              t: 11,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [74.611, 74.611, 100],
              t: 12,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100, 100, 100],
              t: 13,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.194, 115.194, 100],
              t: 14,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [123.915, 123.915, 100],
              t: 15,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.459, 127.459, 100],
              t: 16,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.19, 127.19, 100],
              t: 17,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [124.392, 124.392, 100],
              t: 18,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [120.167, 120.167, 100],
              t: 19,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.394, 115.394, 100],
              t: 20,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [110.711, 110.711, 100],
              t: 21,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [106.541, 106.541, 100],
              t: 22,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [103.116, 103.116, 100],
              t: 23,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.52, 100.52, 100],
              t: 24,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.73, 98.73, 100],
              t: 25,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.652, 97.652, 100],
              t: 26,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.156, 97.156, 100],
              t: 27,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.097, 97.097, 100],
              t: 28,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.337, 97.337, 100],
              t: 29,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.754, 97.754, 100],
              t: 30,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.25, 98.25, 100],
              t: 31,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.751, 98.751, 100],
              t: 32,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.207, 99.207, 100],
              t: 33,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.589, 99.589, 100],
              t: 34,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.885, 99.885, 100],
              t: 35,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.094, 100.094, 100],
              t: 36,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.225, 100.225, 100],
              t: 37,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.145, 100.145, 100],
              t: 43,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.053, 100.053, 100],
              t: 45,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
          ],
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Elastic Controller",
          np: 5,
          mn: "Pseudo/MDS Elastic Controller",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Amplitude",
              mn: "Pseudo/MDS Elastic Controller-0001",
              ix: 1,
              v: { a: 0, k: 20, ix: 1 },
            },
            {
              ty: 0,
              nm: "Frequency",
              mn: "Pseudo/MDS Elastic Controller-0002",
              ix: 2,
              v: { a: 0, k: 40, ix: 2 },
            },
            {
              ty: 0,
              nm: "Decay",
              mn: "Pseudo/MDS Elastic Controller-0003",
              ix: 3,
              v: { a: 0, k: 60, ix: 3 },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [25.184, -6.622],
                    [12.981, -8.509],
                    [7.553, -11.068999999999999],
                    [2.183, -22.196],
                    [-0.7380000000000002, -24.483],
                    [-6.306, -13.456],
                    [-10.416, -9.087],
                    [-22.659, -7.419],
                    [-25.738000000000003, -5.348],
                    [-16.968, 3.356],
                    [-14.078, 8.614999999999998],
                    [-16.278, 20.773],
                    [-15.258, 24.343],
                    [-4.273, 18.692],
                    [1.625, 17.572],
                    [12.506, 23.42],
                    [16.215, 23.553],
                    [14.234, 11.352],
                    [14.991, 5.401],
                    [23.913, -3.138],
                  ],
                  o: [
                    [22.693, -7.008],
                    [10.489, -8.896],
                    [6.455, -13.341],
                    [1.0889999999999997, -24.468],
                    [-1.874, -22.232],
                    [-7.441, -11.206],
                    [-12.913, -8.747],
                    [-25.157999999999998, -7.079],
                    [-23.949, -3.572],
                    [-15.176, 5.132],
                    [-14.53, 11.097],
                    [-16.726, 23.255],
                    [-13.016, 23.188],
                    [-2.0309999999999997, 17.539],
                    [3.844, 18.764],
                    [14.727, 24.612000000000002],
                    [15.81, 21.064],
                    [13.832, 8.863],
                    [16.813, 3.658],
                    [25.734, -4.882],
                  ],
                  v: [
                    [22.693, -7.008],
                    [12.981, -8.509],
                    [6.455, -13.341],
                    [2.183, -22.196],
                    [-1.874, -22.232],
                    [-6.306, -13.456],
                    [-12.913, -8.747],
                    [-22.659, -7.419],
                    [-23.949, -3.572],
                    [-16.968, 3.356],
                    [-14.53, 11.097],
                    [-16.278, 20.773],
                    [-13.016, 23.188],
                    [-4.273, 18.692],
                    [3.844, 18.764],
                    [12.506, 23.42],
                    [15.81, 21.064],
                    [14.234, 11.352],
                    [16.813, 3.658],
                    [23.913, -3.138],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Badge Star",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 8,
      op: 308,
      st: 8,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Badge Circle",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 243.614, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          k: [
            {
              s: [0, 0, 100],
              t: 6,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [7.678, 7.678, 100],
              t: 7,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [25.248, 25.248, 100],
              t: 8,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [48.393, 48.393, 100],
              t: 9,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [74.611, 74.611, 100],
              t: 10,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100, 100, 100],
              t: 11,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.194, 115.194, 100],
              t: 12,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [123.915, 123.915, 100],
              t: 13,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.459, 127.459, 100],
              t: 14,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.19, 127.19, 100],
              t: 15,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [124.392, 124.392, 100],
              t: 16,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [120.167, 120.167, 100],
              t: 17,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.394, 115.394, 100],
              t: 18,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [110.711, 110.711, 100],
              t: 19,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [106.541, 106.541, 100],
              t: 20,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [103.116, 103.116, 100],
              t: 21,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.52, 100.52, 100],
              t: 22,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.73, 98.73, 100],
              t: 23,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.652, 97.652, 100],
              t: 24,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.156, 97.156, 100],
              t: 25,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.097, 97.097, 100],
              t: 26,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.337, 97.337, 100],
              t: 27,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.754, 97.754, 100],
              t: 28,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.25, 98.25, 100],
              t: 29,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.751, 98.751, 100],
              t: 30,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.207, 99.207, 100],
              t: 31,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.589, 99.589, 100],
              t: 32,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.885, 99.885, 100],
              t: 33,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.094, 100.094, 100],
              t: 34,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.225, 100.225, 100],
              t: 35,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.145, 100.145, 100],
              t: 41,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.053, 100.053, 100],
              t: 43,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
          ],
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Elastic Controller",
          np: 5,
          mn: "Pseudo/MDS Elastic Controller",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Amplitude",
              mn: "Pseudo/MDS Elastic Controller-0001",
              ix: 1,
              v: { a: 0, k: 20, ix: 1 },
            },
            {
              ty: 0,
              nm: "Frequency",
              mn: "Pseudo/MDS Elastic Controller-0002",
              ix: 2,
              v: { a: 0, k: 40, ix: 2 },
            },
            {
              ty: 0,
              nm: "Decay",
              mn: "Pseudo/MDS Elastic Controller-0003",
              ix: 3,
              v: { a: 0, k: 60, ix: 3 },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [51.588, -27.308],
                    [27.308, 51.589000000000006],
                    [-51.588, 27.307],
                    [-27.307, -51.587],
                  ],
                  o: [
                    [50.585, 29.122],
                    [-29.121, 50.586000000000006],
                    [-50.586, -29.122],
                    [29.122, -50.586000000000006],
                  ],
                  v: [
                    [51.086, 0.907],
                    [-0.907, 51.087],
                    [-51.086, -0.908],
                    [0.907, -51.087],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.851, 0.8784, 0.902, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Badge Circle",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 6,
      op: 306,
      st: 6,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Badge Zig Zag Circle 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [249.999, 243.614, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          k: [
            {
              s: [0, 0, 100],
              t: 4,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [7.678, 7.678, 100],
              t: 5,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [25.248, 25.248, 100],
              t: 6,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [48.393, 48.393, 100],
              t: 7,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [74.611, 74.611, 100],
              t: 8,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100, 100, 100],
              t: 9,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.194, 115.194, 100],
              t: 10,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [123.915, 123.915, 100],
              t: 11,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.459, 127.459, 100],
              t: 12,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.19, 127.19, 100],
              t: 13,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [124.392, 124.392, 100],
              t: 14,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [120.167, 120.167, 100],
              t: 15,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.394, 115.394, 100],
              t: 16,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [110.711, 110.711, 100],
              t: 17,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [106.541, 106.541, 100],
              t: 18,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [103.116, 103.116, 100],
              t: 19,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.52, 100.52, 100],
              t: 20,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.73, 98.73, 100],
              t: 21,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.652, 97.652, 100],
              t: 22,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.156, 97.156, 100],
              t: 23,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.097, 97.097, 100],
              t: 24,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.337, 97.337, 100],
              t: 25,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.754, 97.754, 100],
              t: 26,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.25, 98.25, 100],
              t: 27,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.751, 98.751, 100],
              t: 28,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.207, 99.207, 100],
              t: 29,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.589, 99.589, 100],
              t: 30,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.885, 99.885, 100],
              t: 31,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.094, 100.094, 100],
              t: 32,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.225, 100.225, 100],
              t: 33,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.145, 100.145, 100],
              t: 39,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.053, 100.053, 100],
              t: 41,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
          ],
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Elastic Controller",
          np: 5,
          mn: "Pseudo/MDS Elastic Controller",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Amplitude",
              mn: "Pseudo/MDS Elastic Controller-0001",
              ix: 1,
              v: { a: 0, k: 20, ix: 1 },
            },
            {
              ty: 0,
              nm: "Frequency",
              mn: "Pseudo/MDS Elastic Controller-0002",
              ix: 2,
              v: { a: 0, k: 40, ix: 2 },
            },
            {
              ty: 0,
              nm: "Decay",
              mn: "Pseudo/MDS Elastic Controller-0003",
              ix: 3,
              v: { a: 0, k: 60, ix: 3 },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [64.53699999999999, 0.567],
                    [64.47, 0.028999999999999998],
                    [64.43299999999999, -0.22899999999999998],
                    [64.36699999999999, -0.531],
                    [64.30199999999999, -0.81],
                    [64.197, -1.193],
                    [57.769000000000005, -9.809],
                    [59.058, -26.641],
                    [45.745000000000005, -36.706],
                    [37.888, -52.553],
                    [21.35, -54.659000000000006],
                    [6.6530000000000005, -64.444],
                    [-9.017, -57.964999999999996],
                    [-26.637, -59.053999999999995],
                    [-36.711, -45.748999999999995],
                    [-52.549, -37.884],
                    [-56.142999999999994, -31.48],
                    [-56.297, -30.462],
                    [-56.313, -30.367],
                    [-56.364000000000004, -29.933],
                    [-56.386, -29.719],
                    [-56.416000000000004, -29.292],
                    [-56.438, -28.644],
                    [-56.43, -27.878],
                    [-56.327000000000005, -26.058999999999997],
                    [-56.232, -25.124],
                    [-56.18, -24.66],
                    [-55.973, -22.835],
                    [-55.9, -22.025],
                    [-55.811, -21.007],
                    [-55.775000000000006, -20.139],
                    [-55.818999999999996, -17.319],
                    [-56.239, -15.86],
                    [-56.379000000000005, -15.456],
                    [-56.541, -15.049999999999999],
                    [-56.725, -14.653],
                    [-56.931, -14.254999999999999],
                    [-57.269, -13.644],
                    [-60.428, -9.455],
                    [-62.122, -7.127],
                    [-63.446999999999996, -5.109999999999999],
                    [-63.977000000000004, -3.851],
                    [-64.111, -3.483],
                    [-64.161, -3.328],
                    [-64.235, -3.092],
                    [-64.316, -2.806],
                    [-64.361, -2.629],
                    [-64.42699999999999, -2.319],
                    [-64.49300000000001, -1.8699999999999999],
                    [-64.545, -1.341],
                    [-57.961, 9.012],
                    [-56.615, 14.447],
                    [-56.583999999999996, 14.668000000000001],
                    [-56.577, 14.705],
                    [-56.511, 15.043000000000001],
                    [-56.453, 15.566],
                    [-56.416, 16.096],
                    [-56.400999999999996, 17.230999999999998],
                    [-56.43, 17.731],
                    [-56.467, 18.349],
                    [-56.497, 18.658],
                    [-56.556000000000004, 19.285],
                    [-56.665, 20.177],
                    [-56.725, 20.596],
                    [-57.049, 22.791],
                    [-57.226, 24.196],
                    [-57.342999999999996, 25.293999999999997],
                    [-57.438, 27.362000000000002],
                    [-57.379, 28.143],
                    [-57.004, 30.330000000000002],
                    [-55.936, 32.127],
                    [-54.111, 33.902],
                    [-52.667, 34.77],
                    [-51.629, 35.33],
                    [-50.538999999999994, 35.845],
                    [-48.78, 36.625],
                    [-47.012, 37.406],
                    [-45.48, 38.143],
                    [-42.837, 39.719],
                    [-37.888999999999996, 52.546],
                    [-21.351, 54.66],
                    [-13.199, 57.472],
                    [-11.432, 58.782000000000004],
                    [-10.828, 59.245999999999995],
                    [-9.709, 60.129000000000005],
                    [-8.655999999999999, 60.970000000000006],
                    [-6.822, 62.353],
                    [-2.8689999999999998, 64.51],
                    [9.016, 57.958000000000006],
                    [18.331000000000003, 56.403999999999996],
                    [21.423000000000002, 56.83],
                    [22.374000000000002, 56.979],
                    [28.073, 57.715],
                    [30.967, 56.655],
                    [31.57, 56.308],
                    [32.256, 55.829],
                    [37.07, 45.403],
                    [47.461, 38.57],
                    [51.29, 36.677],
                    [53.315, 35.514],
                    [54.985, 33.843],
                    [55.538999999999994, 32.914],
                    [55.788000000000004, 32.354],
                    [56.473, 29.41],
                    [56.415, 27.503],
                    [56.385999999999996, 26.942],
                    [56.347, 26.383000000000003],
                    [56.312, 25.912],
                    [56.282, 25.677000000000003],
                    [56.245, 25.249],
                    [56.179, 24.680999999999997],
                    [56.142, 24.262],
                    [56.024, 23.232],
                    [55.958, 22.614],
                    [55.803000000000004, 20.957],
                    [55.753, 19.381],
                    [55.774, 18.689],
                    [55.818, 17.988999999999997],
                    [55.877, 17.444],
                    [55.899, 17.304],
                    [55.943, 17.039],
                    [56.016999999999996, 16.678],
                    [56.113, 16.266],
                    [57.394, 13.328],
                    [58.838, 11.369],
                    [59.301, 10.766],
                    [60.14, 9.713],
                    [60.973, 8.651],
                    [62.357000000000006, 6.8180000000000005],
                    [63.402, 5.103],
                    [63.931999999999995, 3.977],
                    [64.529, 2.004],
                  ],
                  o: [
                    [64.49199999999999, 0.15500000000000003],
                    [64.44, -0.162],
                    [64.396, -0.413],
                    [64.32199999999999, -0.73],
                    [64.24199999999999, -1.046],
                    [62.702, -5.81],
                    [55.383, -19.396],
                    [53.86, -36],
                    [38.316, -44.416],
                    [28.72, -58.075],
                    [11.07, -57.605000000000004],
                    [-4.355, -64.643],
                    [-19.392, -55.379999999999995],
                    [-35.995999999999995, -53.863],
                    [-44.413, -38.312],
                    [-55.841, -32.428],
                    [-56.297, -30.462999999999997],
                    [-56.305, -30.410999999999998],
                    [-56.342, -30.131],
                    [-56.386, -29.741],
                    [-56.401, -29.506],
                    [-56.43, -28.857000000000003],
                    [-56.438, -28.159],
                    [-56.394, -26.840000000000003],
                    [-56.239000000000004, -25.197999999999997],
                    [-56.202, -24.858999999999998],
                    [-56.068999999999996, -23.651],
                    [-55.9, -22.032],
                    [-55.856, -21.516],
                    [-55.782000000000004, -20.323],
                    [-55.730000000000004, -18.607],
                    [-56.193999999999996, -15.994],
                    [-56.327, -15.596],
                    [-56.489000000000004, -15.190000000000001],
                    [-56.666, -14.786],
                    [-56.865, -14.388000000000002],
                    [-57.129, -13.879999999999999],
                    [-58.654, -11.405999999999999],
                    [-62.010999999999996, -7.282],
                    [-62.873000000000005, -6.052],
                    [-63.919, -3.9989999999999997],
                    [-64.07300000000001, -3.593],
                    [-64.15400000000001, -3.3510000000000004],
                    [-64.205, -3.2039999999999997],
                    [-64.29400000000001, -2.8939999999999997],
                    [-64.345, -2.673],
                    [-64.39800000000001, -2.452],
                    [-64.47099999999999, -2.032],
                    [-64.52900000000001, -1.525],
                    [-64.641, 4.352],
                    [-56.65, 14.277000000000001],
                    [-56.585, 14.645],
                    [-56.577, 14.698],
                    [-56.541, 14.874],
                    [-56.467, 15.39],
                    [-56.423, 15.92],
                    [-56.394, 16.744],
                    [-56.43, 17.717],
                    [-56.445, 18.040000000000003],
                    [-56.497, 18.658],
                    [-56.526, 18.975],
                    [-56.63, 19.889],
                    [-56.716, 20.529999999999998],
                    [-56.88, 21.721999999999998],
                    [-57.202999999999996, 24.02],
                    [-57.3, 24.838],
                    [-57.431, 26.567999999999998],
                    [-57.379, 28.137],
                    [-57.269, 29.269000000000002],
                    [-56.232, 31.721],
                    [-54.943, 33.260000000000005],
                    [-52.932, 34.623000000000005],
                    [-52.027, 35.132000000000005],
                    [-50.885, 35.691],
                    [-49.494, 36.324],
                    [-47.543, 37.171],
                    [-45.981, 37.893],
                    [-43.861, 39.026],
                    [-38.308, 44.416],
                    [-28.720999999999997, 58.068],
                    [-14.635000000000002, 56.58],
                    [-11.601, 58.657],
                    [-11.05, 59.076],
                    [-10.166, 59.767999999999994],
                    [-8.958, 60.735],
                    [-7.5889999999999995, 61.794000000000004],
                    [-4.4799999999999995, 63.818],
                    [4.355, 64.635],
                    [16.084, 56.205000000000005],
                    [21.011000000000003, 56.772999999999996],
                    [22.101, 56.934],
                    [25.386000000000003, 57.413000000000004],
                    [30.635, 56.802],
                    [31.43, 56.396],
                    [31.989, 56.028000000000006],
                    [36.157, 52.376],
                    [43.093, 39.593],
                    [51.127, 36.765],
                    [52.422999999999995, 36.088],
                    [54.552, 34.357],
                    [55.436, 33.092],
                    [55.708, 32.547000000000004],
                    [56.347, 30.867999999999995],
                    [56.422000000000004, 27.686999999999998],
                    [56.4, 27.134999999999998],
                    [56.363, 26.575],
                    [56.326, 26.059],
                    [56.288, 25.718999999999998],
                    [56.266999999999996, 25.441000000000003],
                    [56.199999999999996, 24.873],
                    [56.156, 24.38],
                    [56.077, 23.688000000000002],
                    [55.972, 22.694],
                    [55.87, 21.744],
                    [55.76, 19.786],
                    [55.767, 18.828],
                    [55.795, 18.269],
                    [55.862, 17.576999999999998],
                    [55.899, 17.304],
                    [55.921, 17.171999999999997],
                    [55.995000000000005, 16.796],
                    [56.076, 16.413],
                    [56.561, 14.698],
                    [58.608999999999995, 11.663],
                    [59.176, 10.919],
                    [59.778999999999996, 10.161999999999999],
                    [60.729, 8.961],
                    [61.797, 7.5840000000000005],
                    [63.137, 5.574],
                    [63.792, 4.3],
                    [64.344, 2.835],
                    [64.551, 0.8539999999999999],
                  ],
                  v: [
                    [64.499, 0.28],
                    [64.448, -0.095],
                    [64.419, -0.295],
                    [64.338, -0.648],
                    [64.279, -0.891],
                    [64.146, -1.348],
                    [56.694, -14.116],
                    [56.481, -31.288],
                    [42.027, -40.558],
                    [33.271, -55.329],
                    [16.122, -56.154],
                    [1.145, -64.54],
                    [-14.12, -56.699],
                    [-31.284, -56.477],
                    [-40.562, -42.031],
                    [-55.333, -33.275],
                    [-56.297, -30.47],
                    [-56.297, -30.455],
                    [-56.32, -30.322],
                    [-56.386, -29.741],
                    [-56.386, -29.719],
                    [-56.423, -29.071],
                    [-56.438, -28.43],
                    [-56.423, -27.606],
                    [-56.246, -25.272],
                    [-56.224, -25.058],
                    [-56.157, -24.461],
                    [-55.9, -22.032],
                    [-55.9, -22.025],
                    [-55.789, -20.507],
                    [-55.767, -19.955],
                    [-56.157, -16.134],
                    [-56.282, -15.728],
                    [-56.438, -15.323],
                    [-56.607, -14.918],
                    [-56.798, -14.521],
                    [-57.004, -14.123],
                    [-57.417, -13.408],
                    [-61.894, -7.437],
                    [-62.225, -6.973],
                    [-63.852, -4.138],
                    [-64.036, -3.696],
                    [-64.147, -3.373],
                    [-64.169, -3.307],
                    [-64.257, -2.989],
                    [-64.331, -2.717],
                    [-64.361, -2.585],
                    [-64.448, -2.187],
                    [-64.507, -1.716],
                    [-64.545, -1.149],
                    [-56.695, 14.115],
                    [-56.592, 14.616],
                    [-56.577, 14.698],
                    [-56.577, 14.705],
                    [-56.497, 15.22],
                    [-56.445, 15.743],
                    [-56.416, 16.273],
                    [-56.43, 17.717],
                    [-56.43, 17.731],
                    [-56.497, 18.658],
                    [-56.497, 18.666],
                    [-56.6, 19.602],
                    [-56.702, 20.464],
                    [-56.732, 20.662],
                    [-57.181, 23.844],
                    [-57.241, 24.381],
                    [-57.372, 25.743],
                    [-57.372, 28.128],
                    [-57.372, 28.143],
                    [-56.474, 31.28],
                    [-55.605, 32.502],
                    [-53.183, 34.469],
                    [-52.402, 34.917],
                    [-51.224, 35.521],
                    [-50.193, 36.007],
                    [-48.073, 36.942],
                    [-46.497, 37.65],
                    [-44.994, 38.408],
                    [-42.027, 40.565],
                    [-33.272, 55.329],
                    [-16.123, 56.153],
                    [-11.778, 58.525],
                    [-11.263, 58.914],
                    [-10.615, 59.416],
                    [-9.26, 60.49],
                    [-8.347, 61.206],
                    [-6.042, 62.839],
                    [-1.146, 64.54],
                    [14.118, 56.691],
                    [20.606, 56.72],
                    [21.829, 56.89],
                    [22.646, 57.015],
                    [30.297, 56.92],
                    [31.284, 56.478],
                    [31.71, 56.212],
                    [32.506, 55.601],
                    [40.56, 42.03],
                    [50.951, 36.847],
                    [51.452, 36.595],
                    [54.058, 34.822],
                    [55.333, 33.268],
                    [55.62, 32.731],
                    [55.862, 32.163],
                    [56.429, 27.871],
                    [56.407, 27.319],
                    [56.379, 26.758],
                    [56.333, 26.199],
                    [56.297, 25.764],
                    [56.282, 25.632],
                    [56.223, 25.058],
                    [56.164, 24.49],
                    [56.128, 24.146],
                    [55.972, 22.775],
                    [55.951, 22.539],
                    [55.774, 20.19],
                    [55.767, 18.976],
                    [55.781, 18.549],
                    [55.847, 17.717],
                    [55.899, 17.311],
                    [55.899, 17.304],
                    [55.972, 16.907],
                    [56.046, 16.567],
                    [56.156, 16.119],
                    [58.388, 11.965],
                    [59.066, 11.067],
                    [59.418, 10.61],
                    [60.494, 9.263],
                    [61.208, 8.342],
                    [62.843, 6.046],
                    [63.638, 4.617],
                    [64.05, 3.653],
                    [64.544, 1.142],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
              _render: true,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Badge Zig Zag Circle",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 4,
      op: 304,
      st: 4,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Badge Zig Zag Circle",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [249.995, 242.764, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          k: [
            {
              s: [0, 0, 100],
              t: 2,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [7.678, 7.678, 100],
              t: 3,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [25.248, 25.248, 100],
              t: 4,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [48.393, 48.393, 100],
              t: 5,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [74.611, 74.611, 100],
              t: 6,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100, 100, 100],
              t: 7,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.194, 115.194, 100],
              t: 8,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [123.915, 123.915, 100],
              t: 9,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.459, 127.459, 100],
              t: 10,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.19, 127.19, 100],
              t: 11,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [124.392, 124.392, 100],
              t: 12,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [120.167, 120.167, 100],
              t: 13,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.394, 115.394, 100],
              t: 14,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [110.711, 110.711, 100],
              t: 15,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [106.541, 106.541, 100],
              t: 16,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [103.116, 103.116, 100],
              t: 17,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.52, 100.52, 100],
              t: 18,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.73, 98.73, 100],
              t: 19,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.652, 97.652, 100],
              t: 20,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.156, 97.156, 100],
              t: 21,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.097, 97.097, 100],
              t: 22,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.337, 97.337, 100],
              t: 23,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.754, 97.754, 100],
              t: 24,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.25, 98.25, 100],
              t: 25,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.751, 98.751, 100],
              t: 26,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.207, 99.207, 100],
              t: 27,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.589, 99.589, 100],
              t: 28,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.885, 99.885, 100],
              t: 29,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.094, 100.094, 100],
              t: 30,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.225, 100.225, 100],
              t: 31,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.145, 100.145, 100],
              t: 37,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.053, 100.053, 100],
              t: 39,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
          ],
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Elastic Controller",
          np: 5,
          mn: "Pseudo/MDS Elastic Controller",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Amplitude",
              mn: "Pseudo/MDS Elastic Controller-0001",
              ix: 1,
              v: { a: 0, k: 20, ix: 1 },
            },
            {
              ty: 0,
              nm: "Frequency",
              mn: "Pseudo/MDS Elastic Controller-0002",
              ix: 2,
              v: { a: 0, k: 40, ix: 2 },
            },
            {
              ty: 0,
              nm: "Decay",
              mn: "Pseudo/MDS Elastic Controller-0003",
              ix: 3,
              v: { a: 0, k: 60, ix: 3 },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [82.17500000000001, 16.892000000000003],
                    [79.761, 28.651],
                    [81.159, 40.065],
                    [79.916, 46.440999999999995],
                    [63.59, 54.843],
                    [54.055, 66.15299999999999],
                    [48.532, 78.767],
                    [27.767, 79.28200000000001],
                    [13.048, 84.92999999999999],
                    [2.2889999999999997, 92.462],
                    [-9.028, 89.924],
                    [-19.402, 81.431],
                    [-41.111, 83.133],
                    [-53.06100000000001, 72.538],
                    [-57.759, 60.556],
                    [-77.095, 51.529999999999994],
                    [-81.727, 42.805],
                    [-81.558, 32.657000000000004],
                    [-80.571, 22.576999999999998],
                    [-89.673, 8.38],
                    [-92.41900000000001, -0.383],
                    [-82.456, -15.853000000000002],
                    [-80.122, -24.136999999999997],
                    [-80.17399999999999, -32.641999999999996],
                    [-81.012, -44.622],
                    [-63.576, -54.849999999999994],
                    [-51.53, -77.11],
                    [-27.767999999999997, -79.28999999999999],
                    [-6.229000000000001, -92.536],
                    [15.852999999999998, -82.463],
                    [41.118, -83.132],
                    [54.851, -63.583],
                    [77.109, -51.529],
                    [82.374, -38.415],
                    [80.231, -25.116],
                    [81.108, -20.455],
                    [92.535, -6.244],
                    [92.16, 3.866],
                  ],
                  o: [
                    [79.65700000000001, 25.684],
                    [80.431, 35.816],
                    [80.34100000000001, 45.131],
                    [75.232, 54.239],
                    [55.616, 62.538000000000004],
                    [50.512, 74.276],
                    [38.141999999999996, 84.53999999999999],
                    [16.552999999999997, 82.07300000000001],
                    [5.92, 90.12899999999999],
                    [-5.522, 92.32300000000001],
                    [-15.964, 84.482],
                    [-30.565, 78.243],
                    [-51.242999999999995, 77.03],
                    [-56.315000000000005, 64.298],
                    [-65.483, 52.553],
                    [-81.373, 43.834999999999994],
                    [-82.72, 37.599000000000004],
                    [-80.68100000000001, 25.227],
                    [-82.677, 14.079999999999998],
                    [-92.19, 0.8470000000000002],
                    [-92.257, -9.520999999999999],
                    [-80.24, -23.592000000000002],
                    [-79.452, -28.378999999999998],
                    [-80.954, -40.756],
                    [-75.226, -54.239000000000004],
                    [-52.545, -65.49],
                    [-38.129, -84.53899999999999],
                    [-12.900999999999998, -82.971],
                    [9.520999999999999, -92.256],
                    [30.566, -78.244],
                    [54.247, -75.224],
                    [65.491, -52.545],
                    [82.43299999999999, -41.935],
                    [81.387, -30.049999999999997],
                    [81.056, -20.698],
                    [82.97800000000001, -12.908],
                    [92.381, 2.768],
                    [89.60499999999999, 11.354],
                  ],
                  v: [
                    [80.4, 23.085],
                    [80.048, 31.685],
                    [80.563, 43.717],
                    [79.209, 47.626],
                    [58.067, 60.167],
                    [52.479, 69.761],
                    [44.791, 80.851],
                    [20.22, 81.168],
                    [9.469, 87.529],
                    [-1.636, 92.396],
                    [-12.438, 87.235],
                    [-23.077, 80.387],
                    [-47.62, 79.208],
                    [-54.843, 68.032],
                    [-60.16, 58.068],
                    [-80.851, 44.785],
                    [-81.925, 41.707],
                    [-80.998, 27.952],
                    [-81.152, 20.206],
                    [-91.786, 2.047],
                    [-92.397, -1.642],
                    [-80.387, -23.077],
                    [-80.033, -24.682],
                    [-80.564, -36.721],
                    [-79.202, -47.634],
                    [-58.06, -60.166],
                    [-44.778, -80.85],
                    [-20.205, -81.16],
                    [1.642, -92.396],
                    [23.092, -80.386],
                    [47.633, -79.208],
                    [60.174, -58.068],
                    [80.851, -44.792],
                    [81.925, -34.711],
                    [81.011, -20.941],
                    [81.167, -20.212],
                    [92.396, 1.642],
                    [91.785, 4.934],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.851, 0.8784, 0.902, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Badge Zig Zag Circle",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 2,
      op: 302,
      st: 2,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Ribben 2",
      parent: 6,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [32.331, 79.722, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [44.457, 35.879000000000005],
                    [21.42, 35.466],
                    [15.92, 37.153000000000006],
                    [5.317, 56.031],
                    [2.2199999999999998, 58.13999999999999],
                    [-20.346, 12.102],
                    [-22.864, 6.958],
                    [-28.418, -4.364],
                    [-30.088, -7.788],
                    [-33.413, -14.574],
                    [-44.785, -37.777],
                    [-32.897, -43.901],
                    [-6.253, -57.618],
                    [12.682, -24.036],
                    [14.03, -21.658],
                    [20.142, -10.811],
                    [21.9, -7.697],
                    [21.904, -7.693],
                    [44.275, 31.984],
                  ],
                  o: [
                    [41.984, 35.834],
                    [18.935000000000002, 35.425000000000004],
                    [14.704, 39.316],
                    [4.101, 58.193],
                    [1.128, 55.907],
                    [-20.346, 12.102],
                    [-22.864, 6.958],
                    [-28.418, -4.364],
                    [-30.088, -7.788],
                    [-33.413, -14.574],
                    [-44.785, -37.777],
                    [-32.897, -43.901],
                    [-6.253, -57.618],
                    [12.682, -24.036],
                    [14.03, -21.658],
                    [20.142, -10.811],
                    [21.9, -7.697],
                    [21.904, -7.693],
                    [45.491, 34.147],
                  ],
                  v: [
                    [41.984, 35.834],
                    [21.42, 35.466],
                    [14.704, 39.316],
                    [5.317, 56.031],
                    [1.128, 55.907],
                    [-20.346, 12.102],
                    [-22.864, 6.958],
                    [-28.418, -4.364],
                    [-30.088, -7.788],
                    [-33.413, -14.574],
                    [-44.785, -37.777],
                    [-32.897, -43.901],
                    [-6.253, -57.618],
                    [12.682, -24.036],
                    [14.03, -21.658],
                    [20.142, -10.811],
                    [21.9, -7.697],
                    [21.904, -7.693],
                    [44.275, 31.984],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
              _render: true,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.1804, 0.0118, 0.898, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Ribben",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "Ribben",
      parent: 6,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-34.925, 78.579, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [46.029, -36.217],
                    [33.842, -13.432],
                    [30.745, -7.659],
                    [29.074, -4.529],
                    [22.478, 7.807],
                    [19.849, 12.723],
                    [-3.185, 55.783],
                    [-6.244999999999999, 57.95],
                    [-16.165, 38.718],
                    [-20.255, 34.669],
                    [-43.305, 34.264],
                    [-46.748999999999995, 32.452],
                    [-21.64, -8.594],
                    [-19.924, -11.398],
                    [-19.924, -11.402],
                    [-11.641, -24.941],
                    [-10.243, -27.235],
                    [8.22, -57.41],
                    [34.359, -42.759],
                  ],
                  o: [
                    [46.029, -36.217],
                    [33.842, -13.432],
                    [30.745, -7.659],
                    [29.074, -4.529],
                    [22.478, 7.807],
                    [19.849, 12.723],
                    [-4.355, 57.967],
                    [-7.382, 55.746],
                    [-17.302, 36.514],
                    [-22.74, 34.632],
                    [-45.778, 34.223000000000006],
                    [-45.455, 30.335],
                    [-21.64, -8.594],
                    [-19.924, -11.398],
                    [-19.924, -11.402],
                    [-11.641, -24.941],
                    [-10.243, -27.235],
                    [8.22, -57.41],
                    [34.359, -42.759],
                  ],
                  v: [
                    [46.029, -36.217],
                    [33.842, -13.432],
                    [30.745, -7.659],
                    [29.074, -4.529],
                    [22.478, 7.807],
                    [19.849, 12.723],
                    [-3.185, 55.783],
                    [-7.382, 55.746],
                    [-16.165, 38.718],
                    [-22.74, 34.632],
                    [-43.305, 34.264],
                    [-45.455, 30.335],
                    [-21.64, -8.594],
                    [-19.924, -11.398],
                    [-19.924, -11.402],
                    [-11.641, -24.941],
                    [-10.243, -27.235],
                    [8.22, -57.41],
                    [34.359, -42.759],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
              _render: true,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.1804, 0.0118, 0.898, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Ribben",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      ct: 1,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "Bg",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 250, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          k: [
            {
              s: [0, 0, 100],
              t: 0,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [7.678, 7.678, 100],
              t: 1,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [25.248, 25.248, 100],
              t: 2,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [48.393, 48.393, 100],
              t: 3,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [74.611, 74.611, 100],
              t: 4,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100, 100, 100],
              t: 5,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.194, 115.194, 100],
              t: 6,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [123.915, 123.915, 100],
              t: 7,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.459, 127.459, 100],
              t: 8,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [127.19, 127.19, 100],
              t: 9,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [124.392, 124.392, 100],
              t: 10,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [120.167, 120.167, 100],
              t: 11,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [115.394, 115.394, 100],
              t: 12,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [110.711, 110.711, 100],
              t: 13,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [106.541, 106.541, 100],
              t: 14,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [103.116, 103.116, 100],
              t: 15,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.52, 100.52, 100],
              t: 16,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.73, 98.73, 100],
              t: 17,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.652, 97.652, 100],
              t: 18,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.156, 97.156, 100],
              t: 19,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.097, 97.097, 100],
              t: 20,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.337, 97.337, 100],
              t: 21,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [97.754, 97.754, 100],
              t: 22,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.25, 98.25, 100],
              t: 23,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [98.751, 98.751, 100],
              t: 24,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.207, 99.207, 100],
              t: 25,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.589, 99.589, 100],
              t: 26,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [99.885, 99.885, 100],
              t: 27,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.094, 100.094, 100],
              t: 28,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.225, 100.225, 100],
              t: 29,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.145, 100.145, 100],
              t: 35,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
            {
              s: [100.053, 100.053, 100],
              t: 37,
              i: { x: [1, 1, 1], y: [1, 1, 1] },
              o: { x: [0, 0, 0], y: [0, 0, 0] },
            },
          ],
          l: 2,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Elastic Controller",
          np: 5,
          mn: "Pseudo/MDS Elastic Controller",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Amplitude",
              mn: "Pseudo/MDS Elastic Controller-0001",
              ix: 1,
              v: { a: 0, k: 20, ix: 1 },
            },
            {
              ty: 0,
              nm: "Frequency",
              mn: "Pseudo/MDS Elastic Controller-0002",
              ix: 2,
              v: { a: 0, k: 40, ix: 2 },
            },
            {
              ty: 0,
              nm: "Decay",
              mn: "Pseudo/MDS Elastic Controller-0003",
              ix: 3,
              v: { a: 0, k: 60, ix: 3 },
            },
          ],
        },
      ],
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [321.888, 321.888], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
              _render: true,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.94, 0.972, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Bg",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      ct: 1,
      bm: 0,
      completed: true,
    },
  ],
  markers: [],
  __complete: true,
};
