import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";
import { UserIdleService } from "angular-user-idle";
import { LOGIN_PATH } from "../../../../sdk/src/lib/values/shared-paths";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IsUserIdleTimerStopForLoginPageGuard implements CanActivate {
  constructor(
    private _realmClientService: RealmClientService,
    private _router: Router,
    private _userIdleService: UserIdleService
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this._realmClientService.realmClient?.currentUser?.isLoggedIn) {
      this._userIdleService.stopTimer();
      this._userIdleService.resetTimer();
      this._userIdleService.stopWatching();
      this._userIdleService.startWatching();
      return true;
    } else {
      this._userIdleService.stopTimer();
      this._userIdleService.resetTimer();
      this._userIdleService.stopWatching();
      void this._router.navigate(["/", LOGIN_PATH]);
      return false;
    }
  }
}
