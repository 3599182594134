import { Inject, Injectable } from "@angular/core";
import { RealmClientService } from "./realm-client.service";
import { Environment, ENVIRONMENT_TOKEN } from "../types/environment.token";
import { combineLatest, from, Observable } from "rxjs";
import {
  JournalItem,
  JournalItemEntry,
  JournalOrQuest,
} from "../types/journal-item";
import { map } from "rxjs/operators";
import { Quest } from "../types/quest.interface";
import { Course } from "../types/course";
import { SkillEntry } from "../types/module-shell";

@Injectable({
  providedIn: "root",
})
export class JournalService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment,
  ) {}

  getJournalEntryByUserList(
    ownerUserID: string,
  ): Observable<JournalItemEntry[]> {
    const COLLECTION = this._realmClientService.mongoRemoteClient
      .db(this._environmentToken.allContentDB)
      .collection<JournalItemEntry>(
        this._environmentToken.journalEntryCollection,
      );

    return from(
      COLLECTION.find({
        owner_user_id: ownerUserID,
      }),
    );
  }

  getJournalEntryFromOwnerUserIdListArray(
    ownerUserIdList: string[],
  ): Observable<JournalItemEntry[]> {
    const COLLECTION = this._realmClientService.mongoRemoteClient
      .db(this._environmentToken.allContentDB)
      .collection<JournalItemEntry>(
        this._environmentToken.journalEntryCollection,
      );

    return from(
      COLLECTION.find({
        owner_user_id: {
          $in: [...ownerUserIdList],
        },
      }),
    );
  }

  addJournalItemEntry(journalItem: JournalItemEntry): Observable<any> {
    const COLLECTION = this._realmClientService.mongoRemoteClient
      .db(this._environmentToken.allContentDB)
      .collection(this._environmentToken.journalEntryCollection);

    return from(COLLECTION.insertOne(journalItem));
  }

  getJournalItemEntry(journalItemID: object): Observable<any> {
    const COLLECTION = this._realmClientService.mongoRemoteClient
      .db(this._environmentToken.allContentDB)
      .collection(this._environmentToken.journalEntryCollection);

    return from(
      COLLECTION.findOne({
        _id: journalItemID,
      }),
    );
  }

  getOpenJournalItemList$(
    course$: Observable<Course>,
    journalItemEntryList$: Observable<JournalItemEntry[]>,
  ): Observable<JournalItem[]> {
    return combineLatest([course$, journalItemEntryList$]).pipe(
      map(([course, journalItemEntryList]) => {
        const UNLOCKED_WEEK_JOURNAL_ITEMS: JournalItem[] = [].concat.apply(
          [],
          course?.session_list
            .filter((courseSession) => courseSession.is_unlocked === true)
            .map((session_module) => session_module.journal_item_list)
            .filter((item) => item),
        );

        const COMPLETED_JOURNAL_ITEM_ENTRY_LIST = journalItemEntryList
          .map((journalItem) => journalItem.journal_item_id)
          .filter((journalItem) => journalItem !== "");

        return UNLOCKED_WEEK_JOURNAL_ITEMS.filter(
          (journalItem) =>
            COMPLETED_JOURNAL_ITEM_ENTRY_LIST.includes(
              journalItem?._id?.toString(),
            ) === false,
        );
      }),
    );
  }

  getJournalDiaryItemList$(
    journalItemEntryList$: Observable<JournalItemEntry[]>,
    completedQuestList$: Observable<Quest[]>,
    completedSkillEntries$: Observable<SkillEntry[]>,
  ): Observable<Array<JournalOrQuest>> {
    return combineLatest([
      journalItemEntryList$,
      completedQuestList$,
      completedSkillEntries$,
    ]).pipe(
      map(([journalItemsList, questList, completedSkillEntries]) => {
        return [
          ...journalItemsList,
          ...questList,
          ...completedSkillEntries,
        ]?.sort((itemA, itemB) => {
          return (
            new Date(itemB._id.getTimestamp()).getTime() -
            new Date(itemA._id.getTimestamp()).getTime()
          );
        });
      }),
    );
  }
}
