<section class="tw-block">
  <header *ngIf="data?.header?.length > 0">
    {{ data?.header }}
  </header>
  <div
    class="tw-full tw-mx-0 tw-grid tw-aspect-video tw-overflow-hidden tw-rounded-md"
  >
    <video
      (playing)="publishNewAudioPlay(data?.id)"
      class="tw-aspect-video tw-w-full"
      (autoPlay)="(autoPlay)"
      #videoPlayerTemplateREF
      [poster]="
        data?.thumbnail_image_src?.length > 0
          ? getSanitizerURL(data?.thumbnail_image_src)
          : ''
      "
      *ngIf="hasVideoSourceULR; else noVideoUrlTemplate"
      crossorigin="anonymous"
      controls
    >
      <ng-container *ngFor="let source of data?.source_url_list">
        <source
          [src]="getSanitizerURL(source?.source_url)"
          [type]="'video/' + source?.format"
        />
      </ng-container>
      <ng-container *ngFor="let caption of data?.caption_url_list">
        <track
          [label]="caption?.language_label"
          kind="subtitles"
          [srclang]="caption?.language_code"
          [src]="getSanitizerCaptionURL(caption?.source_url)"
        />
      </ng-container>
      Your browser does not support the video tag.
    </video>
  </div>
</section>

<ng-template #noVideoUrlTemplate>
  <video
    class="tw-aspect-video tw-w-full"
    [poster]="
      data?.thumbnail_image_src?.length > 0
        ? getSanitizerURL(data?.thumbnail_image_src)
        : ''
    "
    controls
  >
    Your browser does not support the video tag.
  </video>
</ng-template>
