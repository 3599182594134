import { InjectionToken } from "@angular/core";

export interface Environment {
  addOrganisationFunction: string;
  allContentCollection: string;
  allContentDB: string;
  appID: string;
  createWebinarFunction: string;
  currentProgressCollection: string;
  databaseName: string;
  enrolOrganisationAdminFunction: string;
  enrolSinglePatientFunction: string;
  enrolTherapistFunction: string;
  groupListCollection: string;
  groupProtocolLisCollection: string;
  groupProtocolListDB: string;
  groupsListDB: string;
  journalEntryCollection: string;
  lifePracticeCustomEntriesCollection: string;
  lifePracticeCustomCollection: string;
  lifePracticeCollection: string;
  lifePracticeResultCollection: string;
  lifePracticeResultDB: string;
  liveQuestCollection: string;
  liveQuestDB: string;
  organisationCollection: string;
  production?: boolean;
  profileCollection: string;
  profileDB: string;
  protocolCollection: string;
  protocolDB: string;
  questResultCollection: string;
  questResultDB: string;
  sessionCollection: string;
  sessionDB: string;
  updateWebinarFunction: string;
  userProfileCollection: string;
  userProfileDB: string;
  webinarDB: string;
  webinarListCollection: string;
  getSmsNumberVerificationCodeFunction: string;
  confirmSmsNumberVerificationFunction: string;
  skillEntryCollection: string;
}

export const ENVIRONMENT_TOKEN: InjectionToken<Environment> =
  new InjectionToken("ENVIRONMENT_TOKEN");
