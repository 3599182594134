<section class="tw-block">
  <header *ngIf="data?.header?.length > 0">
    <label [for]="data.id">{{ data?.header }}</label>
  </header>
  <form>
    <fieldset
      class="tw-form-section tw-relative tw-pb-4 [&>textarea]:tw-w-full"
    >
      <textarea
        class="tw-w-full"
        (focusin)="keyboardAboutToShow()"
        (focusout)="keyboardAboutToHide()"
        *ngIf="!isInputDisabled"
        [formControl]="inputFormControl"
        [id]="data.id"
        [maxlength]="data?.config?.input_character_limit || 500"
        [placeholder]="data.config.placeholder"
        [value]="data.value || ''"
        rows="7"
      ></textarea>
      <textarea
        class="tw-w-full"
        *ngIf="isInputDisabled"
        [id]="data.id"
        [maxlength]="data?.config?.input_character_limit || 500"
        [placeholder]="data.config.placeholder || ''"
        [value]="data.value || ''"
        readonly
        rows="7"
      ></textarea>
      <span
        class="tw-absolute -tw-bottom-1 tw-right-1 tw-text-xs tw-font-semibold tw-text-blue-600 tw-opacity-0 tw-transition-opacity"
        [ngClass]="{ 'tw-opacity-100': isFreeTextShow }"
      >
        Character Count : {{ inputFormControl?.value?.length || 0 }} /
        {{ data?.config?.input_character_limit || 500 }}
      </span>
    </fieldset>
  </form>
</section>
