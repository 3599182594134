<section class="tw-block">
  <div
    class="full-width text-center"
    *ngIf="
      data?.collapse_label &&
      data?.collapse_label !== '' &&
      isContentVisible === false
    "
  >
    <button
      type="button"
      class="tw-button"
      (click)="isContentVisible = !isContentVisible"
    >
      <span>{{ data?.collapse_label }}</span>
    </button>
    <p>…</p>
  </div>

  <ng-container *ngIf="isContentVisible === true || !data?.collapse_label">
    <header *ngIf="data?.header?.length > 0">
      {{ data?.header }}
    </header>
    <div
      *ngIf="data?.body?.length > 0"
      class="tw-font-text tw-prose tw-prose-brand md:tw-prose-lg tw-max-w-full"
    >
      <p>{{ data?.body }}</p>
    </div>
  </ng-container>
</section>
