import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractBlockComponent } from "../../abstract-block/abstract-block/abstract-block.component";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SessionStoreService } from "../../services/session-store.service";
import { Block, InputBlock } from "../../types/block";
import { RADIO } from "../../values/blocks";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { first } from "rxjs/operators";

@Component({
  selector: "ail-input-block",
  template: "",
  standalone: true,
})
export class InputBlockComponent
  extends AbstractBlockComponent
  implements OnInit, OnChanges
{
  @Output() abstractControlReady = new EventEmitter<AbstractControl>();

  @Input() data: InputBlock;
  @Input() mode: String;

  inputFormControl: FormGroup | FormControl | AbstractControl;
  isInputDisabled: boolean = false;

  constructor(protected _sessionStore: SessionStoreService) {
    super();
  }

  ngOnInit() {
    if (this.data && this.inputFormControl === undefined) {
      this._instantiateInputFormControl();
      this.abstractControlReady.emit(this.inputFormControl);
    }

    if (this.mode === INPUT_BLOCK_LOCKED_MODE && this.data) {
      this.isInputDisabled = true;
    }
  }

  onBlockDataChange(block: Block) {
    this._sessionStore.onBlockInputValueChanged$.next(block);
  }

  isSelectionChecked(position: number, value: number[]) {
    if (isNullOrUndefined(value)) {
      return false;
    } else {
      return value.includes(position);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !isNullOrUndefined(changes["data"].currentValue) &&
      this.inputFormControl === undefined
    ) {
      this._instantiateInputFormControl();
    }

    if (
      !isNullOrUndefined(
        changes["mode"].previousValue !== INPUT_BLOCK_LOCKED_MODE &&
          changes["mode"].currentValue === INPUT_BLOCK_LOCKED_MODE,
      )
    ) {
      this.isInputDisabled = true;
      this._updateCurrentModuleInputFormGroup();
    }
  }

  private _updateCurrentModuleInputFormGroup() {
    this._sessionStore.currentQuestInputFormGroup$
      .pipe(first())
      .subscribe((formGroup) => {
        if (isNullOrUndefined(this.inputFormControl)) {
          formGroup.addControl(
            this.data.id,
            this._sessionStore.createInputFormControl(this.data),
          );
          this.inputFormControl = formGroup.get(this.data.id);

          this._sessionStore.currentQuestInputFormGroup$.next(formGroup);
        }
      });
  }

  private _instantiateInputFormControl() {
    this.abstractControlReady.emit(
      this._sessionStore.createInputFormControl(this.data),
    );

    this._updateCurrentModuleInputFormGroup();
  }

  onOtherFieldChange($event: Event) {
    this._sessionStore.updateQuestOtherField(
      this.data.id,
      ($event.target as HTMLInputElement).value,
    );
  }

  isOtherFieldSelected(blockData: InputBlock) {
    if (blockData.type === RADIO) {
      if (blockData.value && blockData.value === blockData.options.length) {
        (this.inputFormControl as FormGroup)?.addControl(
          "OTHER_FIELD",
          new FormControl(blockData.config?.other_field?.body || "", [
            Validators.required,
          ]),
        );
      } else {
        (this.inputFormControl as FormGroup)?.removeControl("OTHER_FIELD");
      }
      return blockData.value && blockData.value === blockData.options.length;
    } else {
      if (
        blockData.value &&
        blockData.value.includes(blockData.options.length)
      ) {
        (this.inputFormControl as FormGroup)?.addControl(
          "OTHER_FIELD",
          new FormControl(blockData.config?.other_field?.body || "", [
            Validators.required,
          ]),
        );
      } else {
        (this.inputFormControl as FormGroup)?.removeControl("OTHER_FIELD");
      }
      return (
        blockData.value && blockData.value.includes(blockData.options.length)
      );
    }
  }
}

export const INPUT_BLOCK_LOCKED_MODE = "locked";
