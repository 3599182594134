import * as React from "react";
import { motion } from "framer-motion";
import { useForm, SubmitHandler } from "react-hook-form";
import { SkillsCheckInIntroText, variants } from ".";
import { cn } from "../../libs/utils";
import StepSlider from "../StepSlider";
import { SkillFormData } from "./wrapper";
import { useSkillCheckin } from "./SkillCheckinProvider";
import { QUICK_SKILLS_TRANSLATION, quickSkillRatingOptions } from "./config";

interface SkillFormProps {
  formSubmitWrapper: (data: SkillFormData, cb: () => void) => void;
}

const SkillForm: React.FC<SkillFormProps> = ({ formSubmitWrapper }) => {
  const { setCurrentStep, selectedSkill, selectedModule } = useSkillCheckin();
  const [sliderValue, setSliderValue] = React.useState<number>(1);
  const { course_theme_config } = selectedModule.module_shell;
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<SkillFormData>();

  const onSubmit: SubmitHandler<SkillFormData> = (data) => {
    formSubmitWrapper(
      {
        ...data,
        skill_rating: sliderValue,
        module: selectedModule,
        theme: selectedSkill.theme,
      },
      () => {
        setCurrentStep(3);
      },
    );
  };

  return (
    <motion.form
      className="tw-flex tw-flex-1 tw-flex-col"
      autoComplete="off"
      initial={variants.hide}
      animate={variants.show}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input type="hidden" value={selectedModule.module_item.title} />
      <fieldset className="tw-flex-1">
        <SkillsCheckInIntroText>
          {course_theme_config.skill_header ||
            QUICK_SKILLS_TRANSLATION.FORM.SKILL_HEADER_LABEL}
        </SkillsCheckInIntroText>
        <ul className="tw-mb-6 tw-mt-2 tw-overflow-clip tw-rounded-md tw-border tw-border-border tw-text-sm">
          {course_theme_config.skill_checklist.options?.map(
            ({ value, label, position }) => (
              <li
                key={`option_${position}`}
                className="tw-border-t tw-border-border tw-px-3 tw-py-2 first-of-type:tw-border-t-0"
              >
                <div className="tw-checkbox">
                  <input
                    type="checkbox"
                    id={`checkbox-${position}`}
                    value={value}
                    {...register(`options`, {
                      required: true,
                    })}
                  />
                  <label
                    htmlFor={`checkbox-${position}`}
                    className="tw-flex tw-grow tw-leading-tight"
                  >
                    <span className="tw-mt-0.5">{label}</span>
                  </label>
                </div>
              </li>
            ),
          )}
        </ul>
        <p className="tw-mb-6">
          <label
            className="tw-flex tw-items-baseline tw-justify-between tw-font-semibold"
            htmlFor="textarea"
          >
            {QUICK_SKILLS_TRANSLATION.FORM.SKILL_FEEDBACK_LABEL}
            <small className="tw-text-xs tw-text-muted-foreground">
              ({QUICK_SKILLS_TRANSLATION.FORM.OPTIONAL_LABEL})
            </small>
          </label>
          <textarea
            rows={3}
            className="tw-font-text tw-mt-1 tw-w-full tw-resize-none tw-text-sm"
            id="textarea"
            {...register("skill_feedback")}
          />
        </p>
        <div className="tw-mb-6">
          <label className="tw-mb-2 tw-flex tw-font-semibold">
            {QUICK_SKILLS_TRANSLATION.FORM.SKILL_RATING_LABEL}
          </label>
          <StepSlider value={sliderValue} setValue={setSliderValue} max={2} />
          <div className="tw-flex tw-items-center tw-justify-between">
            {quickSkillRatingOptions.map((label, index: number) => (
              <button
                type="button"
                key={label}
                className={cn(
                  "focus-visible:tw-bg-yellow-100",
                  index === sliderValue &&
                    "tw-font-semibold tw-text-brand-main",
                )}
                disabled={index === sliderValue}
                onClick={() => setSliderValue(index)}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
      </fieldset>
      <p>
        <button
          type="submit"
          className="tw-button tw-w-full"
          disabled={!isValid}
        >
          <span>Save</span>
        </button>
      </p>
    </motion.form>
  );
};

export default SkillForm;
