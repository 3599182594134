export const RADIO = "radio";
export const SMART_RADIO = "smart_radio";
export const SMART_CHECKBOX = "smart_checkbox";
export const CHECKBOX = "checkbox";
export const AUDIO = "audio";
export const VIDEO = "video";
export const IMAGE = "image";
export const CONTAINER = "container";
export const HTML = "html";
export const SELECT = "select";
export const TEXT = "text";
export const FREE_TEXT = "free_text";
export const QUESTIONNAIRE = "questionnaire";
export const PDF = "pdf_file";
