import { Component } from "@angular/core";
import { SmartCheckBoxBlockComponent } from "../../../../../sdk/src/lib/smart-check-box-block/smart-check-box-block/smart-check-box-block.component";
import { SessionStoreService } from "../../../../../sdk/src/lib/services/session-store.service";

@Component({
  selector: "app-uplift-user-smart-check-box-block",
  templateUrl: "./uplift-user-smart-check-box-block.component.html",
  styleUrls: ["./uplift-user-smart-check-box-block.component.css"],
  imports: [],
  standalone: true
})
export class UpliftUserSmartCheckBoxBlockComponent extends SmartCheckBoxBlockComponent {
  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }
}
