export interface IReward {
  level: number;
  label: string;
}

export const REWARDS: IReward[] = [
  {
    level: 1,
    label: "Great start!",
  },
  {
    level: 2,
    label: "Keep going!",
  },
  {
    level: 3,
    label: "Almost there!",
  },
  {
    level: 4,
    label: "You’ve got this!",
  },
];

export const quickSkillRatingOptions: string[] = ["No", "Somewhat", "Very"];

export const QUICK_SKILLS_TRANSLATION: {
  THEMES_HELPER: string;
  MODULES_HELPER: string;
  FORM: {
    SKILL_HEADER_LABEL: string;
    SKILL_FEEDBACK_LABEL: string;
    SKILL_RATING_LABEL: string;
    OPTIONAL_LABEL: string;
  };
  FINAL_SCREEN: {
    AWARD_TITLE: string;
    AWARD_DESC: string;
    COMPLETED_TITLE: string;
    COMPLETED_TIMES: string;
    CLOSE_ACTION: string;
    RECORD_ANOTHER_ACTION: string;
  };
} = {
  THEMES_HELPER:
    "Don’t miss out! When time is short, log your skills practised here. Select the type of skill practised from the categories below:",
  MODULES_HELPER: "Select the skill you've practised to record your experience",
  FORM: {
    SKILL_HEADER_LABEL: "What did you do?",
    SKILL_FEEDBACK_LABEL: "How did it go?",
    SKILL_RATING_LABEL: "Was this practice helpful?",
    OPTIONAL_LABEL: "optional",
  },
  FINAL_SCREEN: {
    AWARD_TITLE: "Your efforts have won you another award!",
    AWARD_DESC:
      "But the real reward is the positive impact you are making in your life! Keep it up.",
    COMPLETED_TITLE: "You have now completed this skill",
    COMPLETED_TIMES: "time(s)",
    CLOSE_ACTION: "Close",
    RECORD_ANOTHER_ACTION: "Record another skill completed",
  },
};
