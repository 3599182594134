import { motion } from "framer-motion";
import * as React from "react";
import { SkillsCheckInIntroText, variants } from ".";
import { useSkillCheckin } from "./SkillCheckinProvider";
import { QUICK_SKILLS_TRANSLATION } from "./config";

const ModulesList: React.FC = () => {
  const { currentStep, setCurrentStep, setSelectedModule, selectedSkill } =
    useSkillCheckin();
  const modules = React.useMemo(() => {
    return selectedSkill.modules_list;
  }, [selectedSkill]);
  return (
    <motion.div
      initial={variants.hide}
      animate={variants.show}
      className="tw-flex tw-flex-1 tw-flex-col"
    >
      <SkillsCheckInIntroText>
        {QUICK_SKILLS_TRANSLATION.MODULES_HELPER}
      </SkillsCheckInIntroText>
      <ul className="tw-my-auto tw-flex tw-flex-col tw-gap-2">
        {modules.map((module) => (
          <li key={module.module_item_id}>
            <button
              type="button"
              className="tw-button tw-w-full"
              onClick={() => {
                setSelectedModule(module);
                setCurrentStep(currentStep + 1);
              }}
            >
              <span>{module.module_item.title}</span>
            </button>
          </li>
        ))}
      </ul>
    </motion.div>
  );
};

export default ModulesList;
