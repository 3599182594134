import { Injectable } from "@angular/core";
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from "@ngxs/store";
import { PROFILE_STATE } from "../../../../../state-name-list/state-names";
import { tap } from "rxjs/operators";
import { PatientProfile } from "../../../../sdk/src/lib/types/profile";
import { FetchPatientProfileAction } from "../actions/patient.actions";
import { RealmClientService } from "../../../../sdk/src/lib/services/realm-client.service";
import { PatientService } from "../../../../sdk/src/lib/services/patient.service";

@State<PatientProfile>({
  name: PROFILE_STATE,
  defaults: null,
})
@Injectable({
  providedIn: "root",
})
export class ProfileState implements NgxsAfterBootstrap {
  constructor(
    private _patientService: PatientService,
    private _realmClientService: RealmClientService,
    private _store: Store
  ) {}

  ngxsAfterBootstrap() {
    if (this._realmClientService.realmClient?.currentUser?.isLoggedIn) {
      this._store.dispatch([new FetchPatientProfileAction()]);
    }
  }

  @Action(FetchPatientProfileAction)
  fetchPatientProfile(stateContext: StateContext<PatientProfile>) {
    return this._patientService
      .getPatientProfileByUserID(
        this._realmClientService.realmClient?.currentUser?.id
      )
      .pipe(
        tap((updatedProfile) => {
          stateContext.patchState({
            ...updatedProfile,
          });
        })
      );
  }
}
