// Build information, automatically generated by `ng-info`
const build = {
    version: "0.0.0",
    timestamp: "Fri Jan 24 2025 17:45:34 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "uplift-user",
        hash: "042ec8",
        fullHash: "042ec89dd80119cece7933449ea2c624bdd59a18"
    }
};

export default build;