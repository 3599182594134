import * as React from "react";
import { ICourseTheme } from "./wrapper";
import { SessionModuleItem } from "projects/sdk/src/lib/types/course";

type SkillCheckinProviderProps = {
  children: React.ReactNode;
};

type SkillCheckinProviderState = {
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  selectedSkill: ICourseTheme | null;
  setSelectedSkill: (selectedSkill: ICourseTheme) => void;
  selectedModule: SessionModuleItem | null;
  setSelectedModule: (selectedModule: SessionModuleItem) => void;
};

const initialState: SkillCheckinProviderState = {
  currentStep: 0,
  selectedSkill: null,
  selectedModule: null,
  setCurrentStep: () => null,
  setSelectedSkill: () => null,
  setSelectedModule: () => null,
};

const SkillCheckinProviderContext =
  React.createContext<SkillCheckinProviderState>(initialState);

export function SkillCheckinProvider({
  children,
  ...props
}: SkillCheckinProviderProps) {
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [selectedSkill, setSelectedSkill] = React.useState<ICourseTheme>(null);
  const [selectedModule, setSelectedModule] =
    React.useState<SessionModuleItem>(null);

  const value = {
    currentStep,
    selectedSkill,
    selectedModule,
    setCurrentStep,
    setSelectedSkill,
    setSelectedModule,
  };

  return (
    <SkillCheckinProviderContext.Provider {...props} value={value}>
      {children}
    </SkillCheckinProviderContext.Provider>
  );
}

export const useSkillCheckin = () => {
  const context = React.useContext(SkillCheckinProviderContext);

  if (context === undefined)
    throw new Error(
      "useSkillCheckin must be used within a SkillCheckinProvider",
    );

  return context;
};
