import { Component, Input, OnInit } from "@angular/core";
import { InputBlockComponent } from "../../input-block/input-block/input-block.component";
import { CheckBoxBlock } from "../../types/block";
import { SessionStoreService } from "../../services/session-store.service";

@Component({
  selector: "ail-check-box-block",
  template: "",
  standalone: true,
})
export class CheckBoxBlockComponent
  extends InputBlockComponent
  implements OnInit
{
  @Input() data: CheckBoxBlock;

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }

  onSelectionChanged($event: Event, position: number) {
    if (!this.data?.value?.length) {
      this.data.value = [];
    }

    if ($event.target["checked"]) {
      this.data.value = [
        ...this.data.value.filter(
          (selectedPosition) => selectedPosition !== position,
        ),
        position,
      ];
    } else {
      this.data.value = [
        ...this.data.value.filter(
          (selectedPosition) => selectedPosition !== position,
        ),
      ];
    }
  }

  onMultiSelectionChanged(selectedPositionValues: number[], blockID: string) {
    this._sessionStore.updateSmartBoxQuestDataForMultiSelect(
      selectedPositionValues,
      blockID,
    );
  }
}
