import { RouteInfo } from "./vertical-menu.metadata";
import {
  EMERGENCY_HELP_PATH,
  HOME_PATH,
  MY_JOURNALS_PATH,
  THERAPIST_SESSION_PATH,
} from "../../../../../sdk/src/lib/values/shared-paths";

export const ROUTES: RouteInfo[] = [
  {
    path: `/${HOME_PATH}`,
    title: "Home",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: `/${THERAPIST_SESSION_PATH}`,
    title: "Course Session",
    icon: "fa fa-users",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: `/${MY_JOURNALS_PATH}`,
    title: "My Journey",
    icon: "ft-book-open",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: `/${EMERGENCY_HELP_PATH}`,
    title: "Emergency Help Kit",
    icon: "fa fa-medkit",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
];
