import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { RadioBlockComponent } from "../../../../../sdk/src/lib/radio-block/radio-block/radio-block.component";
import { SessionStoreService } from "../../../../../sdk/src/lib/services/session-store.service";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-uplift-user-radio-block",
  templateUrl: "./uplift-user-radio-block.component.html",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbTooltip],
})
export class UpliftUserRadioBlockComponent
  extends RadioBlockComponent
  implements AfterViewInit
{
  @ViewChildren("RadioButton") radioButtonElementRefList: QueryList<
    ElementRef<HTMLInputElement>
  >;

  constructor(sessionStore: SessionStoreService) {
    super(sessionStore);
  }

  ngAfterViewInit() {

    this.radioButtonElementRefList.forEach((radioButton, index) => {
      radioButton.nativeElement.checked = false;
      if (
        this.data.value === index ||
        this.data.value === this.data.options.length + 1
      ) {
        radioButton.nativeElement.checked = true;
      }
    });
  }

  checkDisplayModeTextOrImage() {
    return (
      this.data.options?.length > 0 &&
      this.data.options.some((radio) => !!radio.image_label_url)
    );
  }
}
