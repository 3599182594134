<section class="tw-block">
  <header *ngIf="data?.header?.length > 0">
    {{ data?.header }}
  </header>
  <ng-select
    *ngIf="data?.options.length"
    bindLabel="label"
    bindValue="value"
    [formControl]="inputFormControl"
    [items]="data?.options"
    [placeholder]="data?.config?.placeholder"
    class="tw-border-red-500"
  ></ng-select>
</section>
