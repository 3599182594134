import { Component } from "@angular/core";
import { FreeTextBlockComponent } from "../../../../../sdk/src/lib/free-text-block/free-text-block/free-text-block.component";
import { SessionStoreService } from "../../../../../sdk/src/lib/services/session-store.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-uplift-user-free-text-block",
  templateUrl: "./uplift-user-free-text-block.component.html",
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
})
export class UpliftUserFreeTextBlockComponent extends FreeTextBlockComponent {
  isFreeTextShow: boolean = false;
  constructor(
    protected _sessionStore: SessionStoreService,
    private _deviceService: DeviceDetectorService,
  ) {
    super(_sessionStore);
  }

  keyboardAboutToShow() {
    this.isFreeTextShow = true;
    if (this._deviceService.isDesktop() !== true) {
      this._sessionStore.hideHeaderOnMobile.next(true);
    }
  }

  keyboardAboutToHide() {
    this.isFreeTextShow = false;
    if (this._deviceService.isDesktop() !== true) {
      this._sessionStore.hideHeaderOnMobile.next(false);
    }
  }
}
