import { Inject, Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { Quest } from "../types/quest.interface";
import { BSON } from "realm-web";
import { LifePracticeNew } from "../types/life-practice";
import { RealmClientService } from "./realm-client.service";
import { Environment, ENVIRONMENT_TOKEN } from "../types/environment.token";

@Injectable({
  providedIn: "root",
})
export class QuestService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment,
  ) {}

  getQuestCompletedByPatientByReadableID_Count(
    questReadableID: string,
    ownerUserID: String,
  ): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .count({
          readable_id: questReadableID,
          owner_user_id: ownerUserID,
        }),
    );
  }

  getQuest(questID: string): Observable<Quest> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        ?.findOne({
          _id: BSON.ObjectID.createFromHexString(questID),
        }),
    );
  }

  createQuest(quest: Quest): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .insertOne(quest),
    );
  }

  updateQuest(quest: Quest): Observable<any> {
    delete quest._id;
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .updateOne(
          {
            readable_id: quest._id,
          },
          {
            $set: { ...quest },
          },
        ),
    );
  }

  updateModuleShellEntry(moduleID: any, update: object): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.questResultCollection)
        .updateOne(
          {
            _id: moduleID,
          },
          {
            $set: { ...update },
          },
        ),
    );
  }

  deleteModuleShellEntry(moduleID: any): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.questResultCollection)
        .deleteOne({
          _id: moduleID,
        }),
    );
  }

  updateQuestWithUrlSlugChange(
    quest: Quest,
    readable_id: string,
  ): Observable<any> {
    delete quest._id;
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .updateOne(
          {
            readable_id: readable_id,
          },
          {
            $set: { ...quest },
          },
        ),
    );
  }

  getModuleShellEntryList(moduleShellID: string): Observable<Array<Quest>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection<Quest>(this._environmentToken.allContentCollection)
        .find({
          module_shell_id: moduleShellID,
        }),
    );
  }

  getModuleByReadableID(questReadableID: string): Observable<Quest> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .findOne({
          readable_id: questReadableID,
        }),
    );
  }

  getQuestByReadableID(
    questReadableID: string,
  ): Observable<Quest | LifePracticeNew> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .findOne({
          readable_id: questReadableID,
        }),
    );
  }

  getQuestByReadableIdCount(questReadableID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .count({
          readable_id: questReadableID,
        }),
    );
  }

  getQuestByInternalNameCount(questInternalName: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .count({
          internal_name: new RegExp(
            ["^", questInternalName, "$"].join(""),
            "i",
          ),
        }),
    );
  }
  getQuestBySearchInternalNameString(internalName: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find({
          internal_name: new RegExp(internalName, "i"),
        }),
    );
  }
  getQuestByPrefixIdCount(prefixID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .count({
          prefix_id: prefixID,
        }),
    );
  }

  getQuestList(): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find(
          {},
          {
            sort: {
              _id: -1,
            },
          },
        ),
    );
  }

  getModuleListFromModuleObjectIdList(
    moduleObjectIdList: object[],
  ): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find({
          _id: {
            $in: moduleObjectIdList,
          },
        }),
    );
  }

  getQuestsFromQuestObjectID_List(
    questObjectID_List: object[],
  ): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection(this._environmentToken.allContentCollection)
        .find({
          _id: {
            $in: questObjectID_List,
          },
        }),
    );
  }

  getCompletedModuleEntry(moduleEntryID: object): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .findOne({
          _id: moduleEntryID,
        }),
    );
  }

  getQuestCompletedByPatient(ownerUserID: string): Observable<Quest[]> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .find(
          {
            owner_user_id: ownerUserID,
          },
          {
            sort: {
              _id: -1,
            },
          },
        ),
    );
  }

  getSessionListCountForPatient(ownerUserID: string): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .aggregate([
          {
            $match: {
              owner_user_id: ownerUserID,
            },
          },
          {
            $group: {
              _id: "$readable_id",
              count: { $sum: 1 },
              first_member_id: { $first: "$_id" },
            },
          },
        ]),
    );
  }

  getCompletedSessionGroupedByProtocol(ownerUserID: string): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .aggregate([
          {
            $match: {
              owner_user_id: ownerUserID,
            },
          },
          {
            $group: {
              _id: "$readable_id",
              protocol_id: { $first: "$protocol_id" },
              object_id: { $first: "$_id" },
            },
          },
        ]),
    );
  }

  insertOrUpdateModuleShellModuleEntry(
    moduleShellModuleEntry: Quest,
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .updateOne(
          {
            _id: moduleShellModuleEntry._id,
          },
          {
            $set: { ...moduleShellModuleEntry },
          },
          {
            upsert: true,
          },
        ),
    );
  }

  insertModuleShellModuleEntry(moduleShellModuleEntry: Quest): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .insertOne({
          ...moduleShellModuleEntry,
        }),
    );
  }

  setCurrentQuestBeingExecutedByUser(quest: Quest): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.currentProgressCollection)
        .updateOne(
          {
            owner_user_id: quest.owner_user_id,
            group_id: quest.group_id,
            readable_id: quest.readable_id,
            course_id: quest.course_id,
          },
          {
            $set: { ...quest },
          },
          {
            upsert: true,
          },
        ),
    );
  }

  removeModuleInDraftExecutedByUser(
    ownerUserID: string,
    readableID: string,
    groupID: string,
    courseID: string,
    sessionID: string,
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.currentProgressCollection)
        .deleteMany({
          owner_user_id: ownerUserID,
          readable_id: readableID,
          group_id: groupID,
          course_id: courseID,
          session_id: sessionID,
        }),
    );
  }

  getCurrentQuestBeingExecutedByUser(ownerUserID: string): Observable<Quest> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.currentProgressCollection)
        .findOne({
          owner_user_id: ownerUserID,
        }),
    );
  }

  getModuleBeingExecutedByUserList(
    ownerUserID: string,
  ): Observable<Array<Quest>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection<Quest>(this._environmentToken.currentProgressCollection)
        .find({
          owner_user_id: ownerUserID,
        }),
    );
  }

  getModuleBeingExecutedByUser(
    ownerUserID: string,
    moduleReadableID: string,
    courseID: string,
    groupID?: string,
  ): Observable<Quest> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection<Quest>(this._environmentToken.currentProgressCollection)
        .findOne({
          owner_user_id: ownerUserID,
          course_id: courseID,
          group_id: groupID,
          readable_id: moduleReadableID,
        }),
    );
  }

  getLastCompletedSessionOrAssessmentIntervention(userID: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.questResultDB)
        .collection(this._environmentToken.questResultCollection)
        .findOne(
          {
            owner_user_id: userID,
            type: { $in: ["session", "assessment"] },
          },
          {
            sort: { _id: -1 },
          },
        ),
    );
  }
}
