import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Router, Scroll } from "@angular/router";
import { filter } from "rxjs/operators";
import { RealmClientService } from "../../../sdk/src/lib/services/realm-client.service";
import { UserIdleService } from "angular-user-idle";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { LOGIN_PATH } from "../../../sdk/src/lib/values/shared-paths";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("userIdleContainer", { read: TemplateRef })
  public _containerRef: TemplateRef<any>;
  private _timeoutDialog: NgbModalRef;
  subscription: Subscription;

  constructor(
    private _router: Router,
    private _ngModelService: NgbModal,
    private _userIdleService: UserIdleService,
    private _realmClientService: RealmClientService,
    private viewportScroller: ViewportScroller,
  ) { }

  ngOnInit() {
    this._router.events
      .pipe(filter(e => e instanceof Scroll))
      .subscribe((e: any) => {
        setTimeout(() => {
          if (e.position) {
            this.viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            this.viewportScroller.scrollToAnchor(e.anchor);
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }, 0);
      });

    this._userIdleService.onTimerStart().subscribe((time) => {
      if (time === 1) {
        this._timeoutDialog = this._ngModelService.open(this._containerRef, {
          backdrop: "static",
        });
      } else if (time === 60) {
        this.logout();
      }
    });
  }

  keepOpen() {
    this._timeoutDialog?.dismiss();
    this._userIdleService.stopTimer();
    this._userIdleService.resetTimer();
    this._userIdleService.stopWatching();
    this._userIdleService.startWatching();
  }

  logout() {
    this._timeoutDialog?.dismiss();
    this._realmClientService.realmClient.currentUser?.logOut().then(() => {
      this._userIdleService.stopTimer();
      this._userIdleService.resetTimer();
      this._userIdleService.stopWatching();
      void this._router.navigate(["/", LOGIN_PATH]);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
