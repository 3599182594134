import { Root, createRoot } from "react-dom/client";
import * as React from "react";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import { BSON } from "realm-web";
import { Store, Store as NgxsStore } from "@ngxs/store";
import SkillsCheckIn from ".";
import {
  Course,
  CourseTheme,
  SessionModuleItem,
} from "../../../../../sdk/src/lib/types/course";
import { Group } from "../../../../../sdk/src/lib/types/group";
import { SkillEntry } from "../../../../../sdk/src/lib/types/module-shell";
import { CheckBoxBlock } from "projects/sdk/src/lib/types/block";
import { SkillCheckinProvider } from "./SkillCheckinProvider";
import { SkillEntryServiceService } from "projects/sdk/src/lib/services/skill-entry-service.service";
import { ToastrService } from "ngx-toastr";
import { PatientProfile } from "projects/sdk/src/lib/types/profile";
import { Observable } from "rxjs";
import { PROFILE_STATE } from "state-name-list/state-names";
import { GroupState } from "projects/uplift-user-orbit/src/app/states/group-state";
const containerElementRef = "appSkillCheckIn";

@Component({
  selector: "react-skill-check-in",
  template: `<span #${containerElementRef}></span>`,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class SkillCheckInWrapper
  implements OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild(containerElementRef, { static: true }) containerRef!: ElementRef;
  patientProfile$: Observable<PatientProfile> = this._ngxsStore.select(
    (store) => store[PROFILE_STATE],
  );
  assignedGroup$: Observable<Group> = inject(Store).select(GroupState);
  themes_list: ICourseTheme[] = [];
  completedSkillEntries: SkillEntry[] = [];
  private group: Group | null = null;
  private userId: string | null = null;
  private _root: Root;

  constructor(
    private _skillEntryService: SkillEntryServiceService,
    private _toasterService: ToastrService,
    private _ngxsStore: NgxsStore,
  ) {
    this._getSkillEntries();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.assignedGroup$.subscribe((group) => {
      this.group = group;
      this._getThemesList(group?.course);
    });
    this.patientProfile$.subscribe((patient) => {
      this.userId = patient.owner_user_id;
    });
  }

  public handleFormSubmit(data: SkillFormData, cb: () => void) {
    if (!this.group || !this.userId) return;
    const SKILL_ENTRY: SkillEntry = {
      _id: new BSON.ObjectID(),
      skill_checklist: {
        ...data.module.module_shell.course_theme_config.skill_checklist,
        value: data.options,
      } as CheckBoxBlock,
      skill_feedback: data.skill_feedback,
      skill_rating: data.skill_rating,
      skill_header: data.module.module_shell.course_theme_config.skill_header,
      course_id: this.group.course._id.toString(),
      course_title: this.group.course.title,
      group_id: this.group._id.toString(),
      owner_user_id: this.userId,
      created_date: new Date().toISOString(),
      module_shell_id: data.module.module_shell._id.toString(),
      module_id: data.module.module_item_id,
      module_title: data.module.module_item.title,
      theme_id: data.theme._id,
      theme_title: data.theme.title,
    };

    this._skillEntryService.createEntry(SKILL_ENTRY).subscribe({
      next: () => {
        this._getSkillEntries();
        cb();
      },
      error: () => this._toasterService.error("The entry could not be saved."),
      complete: () => this._render(),
    });
  }

  private _getSkillEntries(): void {
    this.patientProfile$.subscribe(({ owner_user_id }) => {
      this._skillEntryService
        .getSkillEntryByPatientIdList(owner_user_id)
        .subscribe((completedSkillEntries) => {
          this.completedSkillEntries = completedSkillEntries;
        });
    });
  }

  private _getThemesList(course: Course | null): void {
    if (!course) return;
    const sessionsModulesList: SessionModuleItem[] = [];
    const courseMenuModulesList: SessionModuleItem[] = [];

    course.session_list.forEach(({ session_module_item_list }) => {
      session_module_item_list.forEach((module) => {
        if (module?.module_shell && module?.module_shell.course_theme_config) {
          sessionsModulesList.push(module);
        }
      });
    });

    course.course_menu_item_list.forEach(({ menu_item_session_list }) => {
      menu_item_session_list.forEach(({ session_module_item_list }) => {
        session_module_item_list.forEach((module) => {
          if (
            module?.module_shell &&
            module?.module_shell?.course_theme_config
          ) {
            courseMenuModulesList.push(module);
          }
        });
      });
    });

    this.themes_list = course.theme_list.map((theme) => ({
      theme,
      modules_list: [
        ...this._filteModulesByThemeId(sessionsModulesList, theme._id),
        ...this._filteModulesByThemeId(courseMenuModulesList, theme._id),
      ],
    }));
  }

  ngOnInit(): void {
    this._root = createRoot(this.containerRef.nativeElement);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this._render();
  }

  ngAfterViewInit() {
    this._render();
  }

  ngOnDestroy() {
    this._root?.unmount();
  }

  private _filteModulesByThemeId(
    modulesList: SessionModuleItem[],
    themeId: string,
  ): SessionModuleItem[] {
    return modulesList.filter(({ module_shell }) =>
      module_shell.course_theme_config.theme_id_list.includes(themeId),
    );
  }

  private _render() {
    this._root?.render(
      <React.StrictMode>
        <SkillCheckinProvider>
          <SkillsCheckIn
            formSubmitWrapper={this.handleFormSubmit}
            themes={this.themes_list}
            completedSkillEntries={this.completedSkillEntries}
          />
        </SkillCheckinProvider>
      </React.StrictMode>,
    );
  }
}

export interface ICourseTheme {
  theme: CourseTheme;
  modules_list: SessionModuleItem[];
}

export interface SkillFormData extends SkillEntry {
  options: any[];
  module: SessionModuleItem;
  theme: CourseTheme;
}
