import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { VideoBlockComponent } from "../../../../../sdk/src/lib/video-block/video-block/video-block.component";
import { Subscription } from "rxjs";
import { VideoBlockService } from "../../../../../sdk/src/lib/services/video-block.service";
import { CommonModule } from "@angular/common";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "app-uplift-user-video-block",
  templateUrl: "./uplift-user-video-block.component.html",
  standalone: true,
  imports: [CommonModule],
})
export class UpliftUserVideoBlockComponent
  extends VideoBlockComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  safeCaptionUrl: SafeResourceUrl;
  private _videoPlaySubscription$: Subscription;

  constructor(
    private _videoBlockService: VideoBlockService,
    private _domSanitizer: DomSanitizer,
  ) {
    super();

    this._videoPauseSubscription$ = _videoBlockService.onPageChange$.subscribe(
      (hasPageChanged) => {
        if (hasPageChanged) {
          this._pauseVideo();
        }
      },
    );

    this._videoPlaySubscription$ = _videoBlockService.onVideoPlaying$.subscribe(
      (audioBlockID) => {
        if (audioBlockID !== this.data?.id) {
          this._pauseVideo();
        }
      },
    );
  }

  getSanitizerURL(url: string): SafeUrl {
    return this._domSanitizer.bypassSecurityTrustUrl(url);
  };

  getSanitizerCaptionURL(url: string): SafeResourceUrl {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private _pauseVideo() {
    if (
      this._videoPlayerTemplateRef !== null &&
      this._videoPlayerTemplateRef !== undefined
    ) {
      this._videoPlayerTemplateRef.nativeElement.pause();
    }
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    if (this.autoPlay) {
      this.playVideo();
    }
  }

  ngOnDestroy(): void {
    this._videoPauseSubscription$.unsubscribe();
    this._videoPlaySubscription$.unsubscribe();
  }

  playVideo(): void {
    this._videoPlayerTemplateRef.nativeElement?.play();
  }

  publishNewAudioPlay(blockID: string) {
    this._videoBlockService.onVideoPlaying$.next(blockID);
  }
}
