import { inject, Injectable } from "@angular/core";
import { RealmClientService } from "./realm-client.service";
import { from, Observable } from "rxjs";
import { ENVIRONMENT_TOKEN } from "../types/environment.token";
import { PatientCondition } from "../types/patient-condition";
import { OrganisationEmergencyInformation } from "../types/organisation";
import { BSON } from "realm-web";

@Injectable({
  providedIn: "root",
})
export class OrganisationService {
  private _realmClientService = inject(RealmClientService);
  private _environmentToken = inject(ENVIRONMENT_TOKEN);

  constructor() {}

  getOrganisationList() {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.databaseName)
        .collection(this._environmentToken.organisationCollection)
        .find({}),
    );
  }

  getOrganisationById(organisationID: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.databaseName)
        .collection(this._environmentToken.organisationCollection)
        .findOne({ _id: new BSON.ObjectId(organisationID) }),
    );
  }

  updateOrganisationEmergencyInformation(
    organisationID: string,
    organisationEmergencyInformation: OrganisationEmergencyInformation,
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.allContentDB)
        .collection<PatientCondition>(
          this._environmentToken.organisationCollection,
        )
        .updateOne(
          {
            _id: new BSON.ObjectId(organisationID),
          },
          {
            $set: {
              ...organisationEmergencyInformation,
            },
          },
        ),
    );
  }
}
