import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { OrganisationService } from "projects/sdk/src/lib/services/organisation.service";
import { Organisation } from "projects/sdk/src/lib/types/organisation";
import { PatientProfile } from "projects/sdk/src/lib/types/profile";
import { PROFILE_STATE } from "state-name-list/state-names";
import { LoaderComponent } from "../shared/components/loader/loader.component";

@Component({
  standalone: true,
  templateUrl: "./emergency-help.component.html",
  selector: "app-emergency-help",
  imports: [CommonModule, LoaderComponent],
})
export class EmergencyHelpComponent {
  private _patientProfile$: Observable<PatientProfile> = this._store.select(
    (store) => store[PROFILE_STATE],
  );
  private _organisationID: string;
  organisationProfile$: Observable<Organisation>;

  constructor(
    private _store: Store,
    private _organisationService: OrganisationService,
  ) {
    this._patientProfile$.subscribe((patientProfile) => {
      this._organisationID = patientProfile?.assigned_organisation_id;
      if (!this._organisationID) return;
      this.organisationProfile$ = this._organisationService.getOrganisationById(
        this._organisationID,
      );
    });
  }
}
