import { motion } from "framer-motion";
import * as React from "react";
import { SkillsCheckInIntroText, variants } from ".";
import { ICourseTheme } from "./wrapper";
import { useSkillCheckin } from "./SkillCheckinProvider";
import { QUICK_SKILLS_TRANSLATION } from "./config";

const ThemesList: React.FC<{
  themes: ICourseTheme[];
}> = ({ themes }) => {
  const { currentStep, setCurrentStep, setSelectedSkill } = useSkillCheckin();
  return (
    <motion.div
      className="tw-flex tw-flex-1 tw-flex-col"
      initial={variants.hide}
      animate={variants.show}
    >
      <SkillsCheckInIntroText>
        {QUICK_SKILLS_TRANSLATION.THEMES_HELPER}
      </SkillsCheckInIntroText>
      <ul className="tw-my-auto tw-flex tw-flex-col tw-gap-2">
        {themes.map((skill: ICourseTheme, index: number) => (
          <li key={`skillChoice_${index}`}>
            <button
              type="button"
              className="tw-button tw-w-full"
              disabled={skill.modules_list.length === 0}
              onClick={() => {
                setSelectedSkill(skill);
                setCurrentStep(currentStep < 3 ? currentStep + 1 : 2);
              }}
            >
              <span>{skill.theme.title}</span>
            </button>
          </li>
        ))}
      </ul>
    </motion.div>
  );
};

export default ThemesList;
