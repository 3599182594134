import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Block, CheckBoxBlockOption, InputBlock } from "../types/block";
import {
  AUDIO,
  CHECKBOX,
  FREE_TEXT,
  IMAGE,
  PDF,
  RADIO,
  SELECT,
  SMART_CHECKBOX,
  SMART_RADIO,
  VIDEO,
} from "../values/blocks";
import { isNullOrUndefined } from "@swimlane/ngx-datatable";
import { Quest } from "../types/quest.interface";
import { BehaviorSubject, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { CONTENT_CASE } from "../values/quest-cases";
import { LifePractice } from "../types/life-practice";
import { Store } from "@ngxs/store";
import { QUEST_STATE } from "../../../../../state-name-list/state-names";
import { UpdateQuestBeingExecutedAction } from "../../../../uplift-user-orbit/src/app/actions/quest.actions";

@Injectable({
  providedIn: "root",
})
export class SessionStoreService {
  questBeingExecuted: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(null);
  isProgressBarVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  currentQuestProgressBarPage: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  currentQuestState: BehaviorSubject<string> = new BehaviorSubject<string>(
    CONTENT_CASE,
  );
  patientProfileLifePractice: BehaviorSubject<LifePractice> =
    new BehaviorSubject<LifePractice>({});
  hideHeaderOnMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  currentQuestInputFormGroup$: BehaviorSubject<FormGroup> =
    new BehaviorSubject<FormGroup>(new FormGroup({}));
  onBlockInputValueChanged$: Subject<Block | null> =
    new Subject<Block | null>();

  constructor(private _store: Store) {}

  instantiatePagesFormGroup() {
    this.currentQuestInputFormGroup$ = new BehaviorSubject<FormGroup>(
      new FormGroup({}),
    );
  }

  resetCurrentFormGroup() {
    this.currentQuestInputFormGroup$.next(new FormGroup({}));
  }

  setQuestStateToResult() {
    this.isProgressBarVisible.next(false);
  }

  updateQuestOtherField(dataID: string, otherFieldValue: string) {
    this.questBeingExecuted.pipe(take(1)).subscribe((quest) => {
      const QUEST = JSON.parse(JSON.stringify(quest));
      const BLOCK_INDEX = QUEST.body.blocks.findIndex((block: Block) => {
        return block.id === dataID;
      });

      (QUEST.body.blocks[BLOCK_INDEX] as InputBlock).config.other_field.body =
        otherFieldValue;
      this.questBeingExecuted.next(QUEST);
    });

    this._store
      .select((store) => store[QUEST_STATE].questBeingExecuted)
      .pipe(take(1))
      .subscribe((quest) => {
        const QUEST = { ...quest };
        const BLOCK_INDEX = QUEST.body.blocks.findIndex((block: Block) => {
          return block.id === dataID;
        });

        (QUEST.body.blocks[BLOCK_INDEX] as InputBlock).config.other_field.body =
          otherFieldValue;

        this._store.dispatch(new UpdateQuestBeingExecutedAction(QUEST));
      });
  }

  private _generateInputFormGroup(block: InputBlock) {
    let formGroup = new FormGroup({});
    const formControl = new FormControl();

    if (isNullOrUndefined(block.value)) {
      formControl.setValue("");
    } else {
      formControl.setValue(block.value.toString());
    }

    if (block.config.required === true) {
      formControl.setValidators(Validators.required);
    }

    if (CHECKBOX_BLOCKS.includes(block.type)) {
      formGroup = this._generateCheckBoxOptionsFormGroup(block);
    } else {
      formGroup.addControl(block.id, formControl);
    }

    return formGroup;
  }

  private _generateCheckBoxOptionsFormGroup(block: InputBlock) {
    const CHECKBOX_OPTIONS_FORM_GROUP = new FormGroup({});

    block.options.forEach((option, index) => {
      let BOOL = false;
      if (block.value && block.value.includes(index)) {
        BOOL = true;
      }
      CHECKBOX_OPTIONS_FORM_GROUP.addControl(
        (option as CheckBoxBlockOption).position.toString(),
        new FormControl(BOOL),
      );
    });

    if (
      block.hasOwnProperty("config") &&
      block.config.hasOwnProperty("other_field") &&
      block.config.other_field.hasOwnProperty("selection_field_text")
    ) {
      CHECKBOX_OPTIONS_FORM_GROUP.addControl(
        block.options.length,
        new FormControl(false),
      );
    }

    return CHECKBOX_OPTIONS_FORM_GROUP;
  }

  createInputFormControl(inputBlock: InputBlock): AbstractControl {
    let inputFormControl: AbstractControl;
    if (FORM_GROUP_BLOCKS.includes(inputBlock.type)) {
      inputFormControl = this._generateInputFormGroup(inputBlock);
    } else {
      const FORM_VALUE = inputBlock.value || "";
      inputFormControl = new FormControl(FORM_VALUE);
    }

    if (inputBlock.config.required === true) {
      inputFormControl.setValidators(Validators.required);
      inputFormControl.updateValueAndValidity();
    }

    return inputFormControl;
  }

  updateSmartBoxQuestData(isChecked: boolean, blockID, position) {
    this.questBeingExecuted.pipe(take(1)).subscribe((quest) => {
      if (quest) {
        const BLOCK_INDEX = quest.body.blocks.findIndex((block) => {
          return block.id === blockID;
        });

        if (isNullOrUndefined(quest.body.blocks[BLOCK_INDEX]?.["value"])) {
          quest.body.blocks[BLOCK_INDEX].value = [];
        }

        if (isChecked === true) {
          if (
            !(quest.body.blocks[BLOCK_INDEX].value as Array<any>).includes(
              position,
            )
          ) {
            (quest.body.blocks[BLOCK_INDEX]?.["value"] as Array<any>).push(
              position,
            );
          }
        } else {
          (quest.body.blocks[BLOCK_INDEX].value as Array<any>) = (
            quest.body.blocks[BLOCK_INDEX].value as Array<any>
          ).filter((checkedPosition) => {
            return checkedPosition !== position;
          });
        }

        this.questBeingExecuted.next(quest);
        this._store.dispatch([new UpdateQuestBeingExecutedAction(quest)]);
      }
    });
  }

  updateSmartBoxQuestDataForMultiSelect(
    selectedPositionValues: number[],
    blockID: string,
  ) {
    this.questBeingExecuted.pipe(take(1)).subscribe((quest) => {
      if (quest) {
        const BLOCK_INDEX = quest.body.blocks.findIndex((block) => {
          return block.id === blockID;
        });

        if (isNullOrUndefined(quest.body.blocks[BLOCK_INDEX].value)) {
          quest.body.blocks[BLOCK_INDEX].value = [];
        }

        (quest.body.blocks[BLOCK_INDEX].value as Array<any>) = [
          ...selectedPositionValues,
        ];

        this.questBeingExecuted.next(quest);
      }
    });
  }

  goToQuestNextPage() {
    this.currentQuestProgressBarPage.pipe(take(1)).subscribe((count) => {
      this.currentQuestProgressBarPage.next(count + 1);
    });
  }

  goToQuestPreviousPage() {
    this.currentQuestProgressBarPage.pipe(take(1)).subscribe((count) => {
      this.currentQuestProgressBarPage.next(count - 1);
    });
  }

  setCurrentPageIndex(pageIndex: number) {
    this.currentQuestProgressBarPage.next(pageIndex);
  }
}

export const CHECKBOX_BLOCKS = [CHECKBOX, SMART_CHECKBOX];
export const FORM_GROUP_BLOCKS = [RADIO, SMART_RADIO, SMART_CHECKBOX, CHECKBOX];
export const INPUT_BLOCK_LIST = [...FORM_GROUP_BLOCKS, FREE_TEXT, SELECT];
export const MEDIA_BLOCK_LIST = [AUDIO, VIDEO, IMAGE, PDF];
