import { Quest } from "../../../../sdk/src/lib/types/quest.interface";
import { Block } from "../../../../sdk/src/lib/types/block";
import { ModuleVariable } from "../../../../sdk/src/lib/types/dimension";

export class FetchCompletedQuestAction {
  static readonly type = "[Completed Quest Page] Fetch Completed Quest";
  constructor() {}
}

export class FetchQuestListInProtocolAction {
  static readonly type = "[Home Page] Fetch Quest List In Protocol";
  constructor(public questIdList: Array<object>) {}
}

export class SetQuestListInProtocolAction {
  static readonly type = "[] Set Quest List In Protocol";
  constructor(public questList: Array<Quest>) {}
}

export class FetchQuestBeingExecutedAction {
  static readonly type =
    "[Quest Page] Set Quest Being Executed Based On Router";
  constructor(public questID: string) {}
}

export class UpdateQuestBeingExecutedAction {
  static readonly type = "[Quest Page] Update Quest Being Executed";

  constructor(public quest: Quest) {}
}

export class UpdateProgressBarVisibilityAction {
  static readonly type = "[Quest Page] Update Quest Progress Bar";

  constructor(public isProgressBarVisible: boolean) {}
}

export class UpdateProgressBarPageAction {
  static readonly type = "[Quest Page] Update Quest Progress Number";

  constructor(public pageNumber: number) {}
}

export class FetchModuleInDraftListAction {
  static readonly type =
    "[Uplift Course Component Page] Fetch List Of Modules In Draft Mode";

  constructor() {}
}

export class UpdateBlockDataAction {
  static readonly type = "[Uplift Course Component Page] Update Block Data";

  constructor(public block: Block) {}
}

export class UpdateModuleVariableListAction {
  static readonly type =
    "[Uplift Module Variable List] Update Module Variable List Action";

  constructor(public moduleVariableList: Array<ModuleVariable>) {}
}

export class ResetModuleBeingExecutedAction {
  static readonly type =
    "[Uplift Usr Quest Course] Reset All Things Associated With Module Being Executed";

  constructor() {}
}
