import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { NgbModalModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { WINDOW_PROVIDERS } from "./shared/services/window.service";
import { ComponentMap } from "../../../sdk/src/lib/types/module-config";
import { SortablejsModule } from "nxt-sortablejs";
import {
  AUDIO,
  CHECKBOX,
  CONTAINER,
  FREE_TEXT,
  HTML,
  IMAGE,
  PDF,
  RADIO,
  SELECT,
  SMART_CHECKBOX,
  SMART_RADIO,
  TEXT,
  VIDEO,
} from "../../../sdk/src/lib/values/blocks";

import { UpliftUserTextBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-text-block-new/uplift-user-text-block/uplift-user-text-block.component";
import { UpliftUserFreeTextBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-free-text-block-new/uplift-user-free-text-block/uplift-user-free-text-block.component";
import { UpliftUserAudioBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-audio-block-new/uplift-user-audio-block/uplift-user-audio-block.component";
import { UpliftUserHtmlBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-html-block-new/uplift-user-html-block/uplift-user-html-block.component";
import { UpliftUserImageBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-image-block-new/uplift-user-image-block/uplift-user-image-block.component";
import { UpliftUserCheckBoxBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-check-box-block-new/uplift-user-check-box-block/uplift-user-check-box-block.component";
import { UpliftUserRadioBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-radio-block-new/uplift-user-radio-block/uplift-user-radio-block.component";
import { UpliftUserSmartRadioBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-smart-radio-block/uplift-user-smart-radio-block/uplift-user-smart-radio-block.component";
import { UpliftUserSmartCheckBoxBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-smart-check-box-block/uplift-user-smart-check-box-block/uplift-user-smart-check-box-block.component";
import { UpliftUserSelectBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-select-block-new/uplift-user-select-block/uplift-user-select-block.component";
import { UpliftUserContainerBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-container-block/uplift-user-container-block/uplift-user-container-block.component";
import { UpliftUserVideoBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-video-block-new/uplift-user-video-block/uplift-user-video-block.component";
import { provideUserIdleConfig } from "angular-user-idle";
import { environment } from "../environments/environment";
import { ToastrModule } from "ngx-toastr";
import { NgxsModule } from "@ngxs/store";
import { ProtocolState } from "./states/protocol.state";
import { ProfileState } from "./states/profile.state";
import { QuestState } from "./states/quest.state";
import { NgSelectModule } from "@ng-select/ng-select";
import { GroupState } from "./states/group-state";
import { JournalItemEntryState } from "./states/journal-item-entry.state";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { RealmClientService } from "../../../sdk/src/lib/services/realm-client.service";
import { ENVIRONMENT_TOKEN } from "../../../sdk/src/lib/types/environment.token";
import {
  AuthConfigToken,
  ComponentMapToken,
} from "../../../sdk/src/lib/values/tokens";
import { UpliftUserModuleConfig } from "./types/module-config";
import { NgxsResetPluginModule } from "ngxs-reset-plugin";
import { ReactiveFormsModule } from "@angular/forms";
import { UpliftUserPdfBlockComponent } from "../../../uplift-orbit-sdk/src/lib/uplift-user-pdf-block-new/uplift-user-pdf-block.component";
import { SkillCheckInWrapper } from "../react/components/SkillCheckIn/wrapper";

export function INITIALISE_REALM(realmClientService: RealmClientService) {
  return () => {
    realmClientService.initialiseRealmClient(environment.appID);
    realmClientService.initialiseMongoRemoteClient();
  };
}

const stitchConfig = environment.realm;
export const authModuleConfig: UpliftUserModuleConfig = {
  stitch: stitchConfig,
};

export const componentMapConfig: ComponentMap = {};
componentMapConfig[TEXT] = UpliftUserTextBlockComponent;
componentMapConfig[FREE_TEXT] = UpliftUserFreeTextBlockComponent;
componentMapConfig[AUDIO] = UpliftUserAudioBlockComponent;
componentMapConfig[HTML] = UpliftUserHtmlBlockComponent;
componentMapConfig[IMAGE] = UpliftUserImageBlockComponent;
componentMapConfig[CHECKBOX] = UpliftUserCheckBoxBlockComponent;
componentMapConfig[RADIO] = UpliftUserRadioBlockComponent;
componentMapConfig[SMART_RADIO] = UpliftUserSmartRadioBlockComponent;
componentMapConfig[SMART_CHECKBOX] = UpliftUserSmartCheckBoxBlockComponent;
componentMapConfig[CHECKBOX] = UpliftUserCheckBoxBlockComponent;
componentMapConfig[SELECT] = UpliftUserSelectBlockComponent;
componentMapConfig[CONTAINER] = UpliftUserContainerBlockComponent;
componentMapConfig[VIDEO] = UpliftUserVideoBlockComponent;
componentMapConfig[PDF] = UpliftUserPdfBlockComponent;

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    NgSelectModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModalModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    NgxsModule.forRoot([
      GroupState,
      ProtocolState,
      ProfileState,
      QuestState,
      JournalItemEntryState,
    ]),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      toastClass: "tw-toast",
      iconClasses: {
        error: "tw-toast-error",
        info: "tw-toast-info",
        success: "tw-toast-success",
        warning: "tw-toast-warning",
      },
    }),
    SortablejsModule.forRoot({
      animation: 150,
    }),
    ReactiveFormsModule,
    SkillCheckInWrapper,
  ],
  providers: [
    provideUserIdleConfig({ idle: 1200, timeout: 600, ping: 60 }),
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: INITIALISE_REALM,
      deps: [RealmClientService],
      multi: true,
    },
    {
      provide: ComponentMapToken,
      useValue: componentMapConfig,
    },
    {
      provide: AuthConfigToken,
      useValue: authModuleConfig,
    },
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment.realm,
    },
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
