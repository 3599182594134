<section class="tw-block">
  <header
    class="!tw-mb-2 tw-bg-background tw-py-2"
    *ngIf="data?.header?.length > 0"
  >
    {{ data?.header }}
  </header>
  <div *ngIf="data.options.length > 0">
    <form
      [formGroup]="inputFormControl"
      *ngIf="inputFormControl"
      class="tw-flex tw-flex-col tw-gap-4"
    >
      <ng-container *ngIf="checkDisplayModeTextOrImage() === true">
        <div
          class="tw-grid tw-grid-cols-2 tw-gap-4"
          [ngClass]="{
            'md:tw-grid-cols-2': [1, '1'].includes(data.config.columns),
            'md:tw-grid-cols-2': [2, '2'].includes(data.config.columns),
            'md:tw-grid-cols-3': [3, '3'].includes(data.config.columns),
            'md:tw-grid-cols-4': [4, '4'].includes(data.config.columns),
            'md:tw-grid-cols-5': [5, '5'].includes(data.config.columns)
          }"
        >
          <ng-container *ngFor="let radioButton of data.options; index as i">
            <label
              class="tw-group tw-relative tw-flex tw-cursor-pointer tw-flex-col"
            >
              <input
                #RadioButton
                [id]="data.id + radioButton?.position?.toString()"
                [value]="radioButton.position"
                [attr.name]="data.id"
                [formControlName]="data.id"
                type="radio"
                class="tw-absolute -tw-left-[100svw]"
                (change)="onSelectionChange(radioButton.position)"
              />
              <div
                class="tw-relative tw-flex tw-aspect-square tw-overflow-clip tw-rounded-md tw-border tw-border-black/10"
              >
                <img
                  [src]="radioButton.image_label_url"
                  alt="{{ radioButton.label }}"
                  class="tw-absolute tw-inset-0 tw-z-0 tw-h-full tw-max-h-full tw-w-full tw-max-w-full tw-object-cover"
                />
                <span
                  class="tw-absolute tw-inset-0 tw-z-0 tw-bg-blue-600/60 tw-opacity-0 tw-backdrop-grayscale tw-transition-opacity group-has-[input:checked]:tw-opacity-100"
                >
                </span>
              </div>

              <p class="tw-peer tw-w-full tw-flex-1 tw-p-2 tw-text-center">
                <span
                  [attr.for]="data?.id + data?.position?.toString()"
                  [ngbTooltip]="imageToolTipTemplateREF"
                  class="tw-inline-flex !tw-leading-none group-has-[input:checked]:tw-text-brand-main"
                >
                  <ng-container *ngIf="radioButton.label !== ''">
                    {{ radioButton.label }}
                  </ng-container>
                  <ng-container *ngIf="radioButton.label === ''">
                    {{ i + 1 }}
                  </ng-container>
                </span>
                <span
                  class="tw-absolute -tw-right-2 -tw-top-2 tw-z-10 tw-flex tw-size-8 tw-scale-75 tw-items-center tw-justify-center tw-rounded-full tw-bg-brand-main tw-opacity-0 tw-transition-all group-has-[input:checked]:tw-scale-100 group-has-[input:checked]:tw-opacity-100"
                >
                  <i class="ft-check tw-font-bold tw-text-white"></i>
                </span>
              </p>
            </label>
          </ng-container>
        </div>
        <div>
          <ng-container
            *ngIf="
              data?.config?.other_field !== undefined &&
              data?.config?.other_field?.selection_field_text
            "
          >
            <label
              [id]="data?.id + data?.options.length.toString() + '-label'"
              [attr.for]="data?.id + data?.options.length.toString()"
            >
              <p class="tw-radio !tw-bg-transparent">
                <input
                  #RadioButton
                  [formControlName]="data.id"
                  [id]="data.id + data.options.length.toString()"
                  [checked]="data.value === data.options.length"
                  [value]="data.options.length"
                  [attr.name]="data.id"
                  [disabled]="isInputDisabled"
                  type="radio"
                  (change)="onSelectionChange(data.options.length)"
                />
                <span
                  *ngIf="data?.config?.other_field?.selection_field_text !== ''"
                  class="!tw-bg-transparent"
                >
                  {{ data?.config?.other_field?.selection_field_text }}
                </span>
              </p>
            </label>
          </ng-container>

          <ng-container *ngIf="isOtherFieldSelected(data)">
            <fieldset
              class="tw-form-section tw-mt-2 tw-flex tw-flex-col tw-gap-2 tw-pb-4 tw-pl-8"
            >
              <label
                [id]="data?.id + 'other-field-label'"
                [for]="data?.id + 'other-field'"
              >
                {{ data?.config?.other_field.label || "" }}
              </label>
              <input
                *ngIf="mode === 'locked'"
                [value]="data?.config?.other_field?.body || ''"
                readonly
                type="text"
                class="tw-mt-1 tw-w-full"
                [id]="data?.id + 'other-field-input'"
                [placeholder]="data?.config?.other_field.placeholder || ''"
              />
              <input
                *ngIf="mode !== 'locked'"
                [formControl]="inputFormControl?.get('OTHER_FIELD')"
                (input)="onOtherFieldChange($event)"
                [value]="data?.config?.other_field?.body || ''"
                [readonly]="isInputDisabled"
                type="text"
                class="form-control"
                [id]="data?.id + 'other-field'"
                [placeholder]="data?.config?.other_field.placeholder || ''"
              />
            </fieldset>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="checkDisplayModeTextOrImage() === false">
        <div
          class="tw-grid tw-overflow-clip tw-rounded-md tw-border tw-border-border"
          [ngClass]="{
            'tw-grid-cols-2 md:tw-grid-cols-2': [2, '2'].includes(
              data.config.columns
            ),
            'tw-grid-cols-2 md:tw-grid-cols-3': [3, '3'].includes(
              data.config.columns
            ),
            'tw-grid-cols-2 md:tw-grid-cols-4': [4, '4'].includes(
              data.config.columns
            ),
            'tw-grid-cols-2 md:tw-grid-cols-5': [5, '5'].includes(
              data.config.columns
            )
          }"
        >
          <ng-container *ngFor="let radioButton of data.options; index as i">
            <div
              class="tw-form-section tw-flex tw-flex-col [&>label]:tw-px-4 [&>label]:tw-py-3"
            >
              <label
                [attr.for]="data?.id + data?.position?.toString()"
                *ngIf="isInputDisabled"
                class="tw-radio tw-flex-1"
              >
                <input
                  #RadioButton
                  [id]="data.id + radioButton?.position?.toString()"
                  [value]="radioButton.position"
                  [attr.name]="data.id"
                  [formControlName]="data.id"
                  readonly
                  type="radio"
                />
                <span
                  [attr.for]="data?.id + data?.position?.toString()"
                  [ngbTooltip]="imageToolTipTemplateREF"
                >
                  {{ radioButton.label }}
                </span>
              </label>
              <label
                [attr.for]="data?.id + radioButton?.position.toString()"
                *ngIf="!isInputDisabled"
                class="tw-radio tw-flex-1 tw-px-4 tw-py-3 before:tw-absolute before:tw-inset-y-0 before:-tw-left-[1px] before:tw-z-10 before:tw-h-svh before:tw-w-[1px] before:tw-bg-border after:tw-absolute after:tw-inset-x-0 after:-tw-bottom-[1px] after:tw-z-10 after:tw-h-[1px] after:tw-w-svw after:tw-bg-border"
              >
                <input
                  #RadioButton
                  [id]="data.id + radioButton.position.toString()"
                  [attr.name]="data.id"
                  [value]="radioButton.position"
                  [checked]="
                    radioButton.position.toString() ===
                    inputFormControl.get(data?.id).value
                  "
                  (change)="onSelectionChange(radioButton.position)"
                  [formControlName]="data.id"
                  type="radio"
                />
                <span>
                  {{ radioButton.label }}
                </span>
              </label>
            </div>
          </ng-container>
          <div
            *ngIf="
              data?.config?.other_field !== undefined &&
              data?.config?.other_field?.selection_field_text
            "
          >
            <label
              [id]="data?.id + data?.options.length.toString() + '-label'"
              [attr.for]="data?.id + data?.options.length.toString()"
              class="tw-radio tw-px-4 tw-py-3 before:tw-absolute before:tw-inset-y-0 before:-tw-left-[1px] before:tw-z-10 before:tw-h-svh before:tw-w-[1px] before:tw-bg-border after:tw-absolute after:tw-inset-x-0 after:-tw-bottom-[1px] after:tw-z-10 after:tw-h-[1px] after:tw-w-svw after:tw-bg-border"
            >
              <input
                #RadioButton
                [formControlName]="data.id"
                [id]="data.id + data.options.length.toString()"
                [checked]="data.value === data.options.length"
                [value]="data.options.length"
                [attr.name]="data.id"
                [disabled]="isInputDisabled"
                type="radio"
                (change)="onSelectionChange(data.options.length)"
              />
              <span
                *ngIf="data?.config?.other_field?.selection_field_text !== ''"
              >
                {{ data?.config?.other_field?.selection_field_text }}
              </span>
            </label>
          </div>
        </div>
        <ng-container *ngIf="isOtherFieldSelected(data)">
          <fieldset
            class="tw-form-section tw-flex tw-flex-col tw-gap-2 tw-px-4 tw-pb-4"
          >
            <label
              [id]="data?.id + 'other-field-label'"
              [for]="data?.id + 'other-field-input'"
              class="flex-1"
            >
              {{ data?.config?.other_field.label || "" }}
            </label>
            <input
              *ngIf="mode === 'locked'"
              [value]="data?.config?.other_field?.body || ''"
              readonly
              type="text"
              class="form-control"
              [id]="data?.id + 'other-field-input'"
              [placeholder]="data?.config?.other_field.placeholder || ''"
            />
            <input
              *ngIf="mode !== 'locked'"
              [formControl]="inputFormControl?.get('OTHER_FIELD')"
              (input)="onOtherFieldChange($event)"
              [value]="data?.config?.other_field?.body || ''"
              [readonly]="isInputDisabled"
              type="text"
              class="form-control"
              [id]="data?.id + 'other-field-input'"
              [placeholder]="data?.config?.other_field.placeholder || ''"
            />
          </fieldset>
        </ng-container>
      </ng-container>
    </form>
  </div>
</section>
