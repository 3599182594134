import { Component } from "@angular/core";

@Component({
  selector: "app-loader",
  standalone: true,
  template: `
    <div
      class="tw-grid tw-min-h-[200px] tw-flex-1 tw-items-center tw-justify-center"
    >
      <span class="tw-origin-center tw-animate-spin tw-text-muted-foreground">
        <i
          class="ft-loader tw-animate-pulse tw-text-3xl tw-text-brand-main"
        ></i>
        <span class="tw-sr-only">Loading…</span>
      </span>
    </div>
  `,
})
export class LoaderComponent {}
